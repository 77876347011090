
<div class="row align-items-center modal-header">
    <div class="col-2"><mat-icon mat-dialog-close>keyboard_backspace</mat-icon></div>
    <div class="col-8"><h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <span> Create Source</span>
    </h2></div>
    <div class="col-2">
    </div>
</div>
<mat-dialog-content class="mr-0 ml-0">
    <form [formGroup]="Form">
        <div class="row mt-2">
            <mat-form-field appearance="outline">
                <mat-label>Source Name</mat-label>
                <input matInput placeholder="" type="text" formControlName="sourcename">
            </mat-form-field>
        </div>
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <input matInput placeholder="" type="text" formControlName="Description">
            </mat-form-field>
        </div>
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>Keywords</mat-label>
                <input matInput placeholder="" type="text" formControlName="Keywords">
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="my-1">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button class="b-blue" [disabled]="!Form.valid" (click)="onSubmit()">Apply</button>
</mat-dialog-actions>