import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiServiceService } from 'src/app/service/api-service.service';
@Component({
  selector: 'app-create-action',
  templateUrl: './create-action.component.html',
  styleUrls: ['./create-action.component.scss']
})
export class CreateActionComponent implements OnInit {
  screentype:any;
  actiondata:any;
  Form: FormGroup;
  MediaFile=null;

  constructor(
    private matDialogRef: MatDialogRef<CreateActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private loginservice: ApiServiceService,
    private snackBar: MatSnackBar,
  ) {

   this.Form = this.formBuilder.group({
      id: [''],
      ScenarioId: [''],
      Stage: [''],
      Title: ['',[Validators.required]],
      Description: [''], 
      ActionType: [''],
      IsMediaIsFile: [false],
      MediaFile: [''],
      MediaContent: [''],
      DelayPeriod:[''],
      Objective: [''],
      Status: [''],
      StartDateTime: [''],
      Created: [new Date()],
      ConditionViewModels: this.formBuilder.array([]),
    });
  } 
  ngOnInit(): void {
    console.log(this.data)
    if(this.data.screentype){
      this.screentype=this.data.screentype
      if(this.data?.data){
        this.actiondata=this.data.data
      }
    }
  }


  onFileChange(event: any) {
    if(event.target.files[0]){
      this.MediaFile = event.target.files[0]
      this.Form.patchValue({MediaFile: this.MediaFile});
    }
  }


  getConditionViewModels(): FormArray {
    return this.Form.get('ConditionViewModels') as FormArray;
  }

  addConditionViewModels(){
    this.getConditionViewModels().push(this.newFile());
  }
  Removecondictuon(i: number) {
    this.getConditionViewModels().removeAt(i);
  }
  newFile(): FormGroup {
    return this.formBuilder.group({
      userActionType: [''],
      comparison: [''],
      value: [''],
    });
  }



  onSubmit(){
    if(this.screentype==='createaction'){
      if(this.Form.valid){
        const formData = new FormData(); 
        formData.append('ScenarioId', this.Form.get('ScenarioId').value);
        formData.append('Stage', this.Form.get('Stage').value);
        formData.append('Title', this.Form.get('Title').value);
        formData.append('Description', this.Form.get('Description').value);
        formData.append('ActionType', this.Form.get('ActionType').value);
        if(this.MediaFile!=null){
          this.Form.get('IsMediaIsFile').setValue(true)
          formData.append('IsMediaIsFile', this.Form.get('IsMediaIsFile').value);
          formData.append('MediaFile', this.MediaFile);
          formData.append('MediaContent', this.Form.get('MediaContent').value);
        }else{
          this.Form.get('IsMediaIsFile').setValue(false)
          formData.append('IsMediaIsFile', this.Form.get('IsMediaIsFile').value);
        }
        formData.append('DelayPeriod', this.Form.get('DelayPeriod').value);
        formData.append('Objective', this.Form.get('Objective').value);
        formData.append('Status', this.Form.get('Status').value);
        formData.append('StartDateTime', this.Form.get('StartDateTime').value);
        formData.append('Created', this.Form.get('Created').value);
        formData.append('ConditionViewModels', this.Form.get('ConditionViewModels').value);
        this.loginservice.CreateAction(formData).subscribe((result)=>{
          if(result){
            console.log(result)
          }
        })

      }
    }
    // console.log(this.Form.value)
    // this.matDialogRef.close('option')
  }

}
