import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiServiceService } from 'src/app/service/api-service.service';

@Component({
  selector: 'app-lead-score-configuration',
  templateUrl: './lead-score-configuration.component.html',
  styleUrls: ['./lead-score-configuration.component.scss'],
})
export class LeadScoreConfigurationComponent implements OnInit {
  conditioncolumns: any;
  Form: FormGroup;
  AllOption = [
    { v1: '=', v2: 'equal' },
    { v1: '!=', v2: 'not equal' },
    { v1: '>', v2: 'greater than' },
    { v1: '<', v2: 'less than ' },
    { v1: '>=', v2: 'greater than or equal' },
    { v1: '<=', v2: 'less than or equal' },
  ];
  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private loginservice: ApiServiceService,
    private matDialogRef: MatDialogRef<LeadScoreConfigurationComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.Form = this.formBuilder.group({
      Configurationname: ['', [Validators.required]],
      description: [''],
      keyword: [''],
      leadScoreConditions: this.formBuilder.array([]),
    });
  }

  ngOnInit(): void {
    this.getconditioncolumns();
  }

  getCondition(): FormArray {
    return this.Form.get('leadScoreConditions') as FormArray;
  }
  newCondition(): FormGroup {
    return this.formBuilder.group({
      conditionParameter: [''],
      conditionComparison: [''],
      value: [''],
      addorSub: [true],
      scorePoint: [''],
      startDate: [''],
      endDate: [''],
    });
  }
  getconditioncolumns() {
    this.loginservice.getconditioncolumns().subscribe((result: any) => {
      if (result) {
        this.conditioncolumns = result;
        console.log(result, 'result');
      }
    });
  }

  Addnewcondictuon() {
    this.getCondition().push(this.newCondition());
  }
  Removecondictuon(i: number) {
    this.getCondition().removeAt(i);
  }

  onSubmit() {
    if (this.Form.valid) {
      const formData = new FormData();
      formData.append('Name', this.Form.value.Configurationname);
      formData.append('Description', this.Form.value.description);
      formData.append('Keyword', this.Form.value.keyword);
      if(this.Form.value.leadScoreConditions){
        this.Form.value.leadScoreConditions.forEach((element,index) => {
          formData.append('leadScoreConditions[' + index + '].conditionParameter', element.conditionParameter);
          formData.append('leadScoreConditions[' + index + '].conditionComparison', element.conditionComparison);
          formData.append('leadScoreConditions[' + index + '].value', element.value);
          formData.append('leadScoreConditions[' + index + '].addorSub', element.addorSub);
          formData.append('leadScoreConditions[' + index + '].scorePoint', element.scorePoint);
          formData.append('leadScoreConditions[' + index + '].startDate', element.startDate);
          formData.append('leadScoreConditions[' + index + '].endDate', element.endDate);
        });
      }
      this.loginservice.CreateLeadScoringCongfig(formData).subscribe((result) => {
          if (result) {
            console.log(result);
            this.matDialogRef.close(true)
          }
        },error=>{
          if(error.status==200){
            this.matDialogRef.close(true)
          }
        });
    }
  }
}
