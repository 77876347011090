

<mat-tab-group mat-align-tabs="center" class="no-bg display" (selectedTabChange)="tabChange($event.index)"
    [disableRipple]="true">
    <!-- 1111111111  -->
    <mat-tab>
        <ng-template mat-tab-label>
            <button mat-raised-button [ngClass]="tabChangecolor(0)">Score Details</button>
        </ng-template>
        <div class="tab-container p-2">
            <div style="width:99%;">
                <div class="row m-0">
                    <form [formGroup]="Form">
                        <div class="row mt-4" > 
                            <div class="col-12 col-sm-12  col-md-6 col-lg-3 col-xl-3">
                                <mat-form-field class="w-100" appearance="outline">
                                        <mat-label><mat-icon>folder</mat-icon> Select a Project *</mat-label> 
                                    <mat-select formControlName="ProjectID">
                                        <mat-option *ngFor="let x of AllProjects" [value]="x.id" (click)="GetAllCampaigns(x.id)">
                                            {{x.projectName}}
                                        </mat-option> 
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-12  col-md-6 col-lg-3 col-xl-3">
                                <mat-form-field class="w-100" appearance="outline">
                                        <mat-label><mat-icon>date_range</mat-icon>select a Campaign*</mat-label>
                                        <mat-select placeholder="Select Campaigns" formControlName="CampaignID"
                                        multiple>
                                        <mat-option *ngFor="let x of AllcampaignsDD" [value]="x.campaignId">
                                            {{x.campaignName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-sm-12  col-md-6 col-lg-3 col-xl-3">
                                <mat-form-field class="w-100" appearance="outline">
                                        <mat-label><mat-icon>date_range</mat-icon> Select a configuration *</mat-label>
                                    <mat-select formControlName="configurationID">
                                        <mat-option *ngFor="let x of AllconfigurationDD"
                                        [value]="x.id">
                                        {{x.name}}
                                    </mat-option> 
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-2 col-sm-2  col-md-4 col-lg-2 col-xl-2">
                                <button mat-raised-button [disabled]="!Form.valid" class="right b-black" style="margin-top: 10px;"
                                    (click)="Viewapply()">Apply</button>
                            </div>
                        </div>
                </form>
                <div>
                    <div class="row">
                        <div class="col-4" *ngFor="let lead of leads">
                            <div class="lead-card">
                            <div class="row">
                                <div class="col-6 mt-4">
                                    <p>Email: {{ lead?.email }}</p>
                                </div>
                                <div class="col-6">
                                        <div  class="circular-progress-bar">
                                            <svg viewBox="0 0 100 100" style="width: 100px !important;">
                                                <circle
                                                    class="progress-bar-background"
                                                    [attr.cx]="50"
                                                    [attr.cy]="50"
                                                    [attr.r]="radius">
                                                </circle>
                                                <circle
                                                    class="progress-bar-Send"
                                                    [attr.cx]="50"
                                                    [attr.cy]="50"
                                                    [attr.r]="radius"
                                                    [attr.stroke-dasharray]="circumference"
                                                    [attr.stroke-dashoffset]="lead?.score">
                                                </circle>
                                                <text x="50" y="50" text-anchor="middle" dominant-baseline="central">
                                                    {{ lead?.score }}
                                            </text>
                                            </svg>
                                        </div>
                                      </div>
                                </div>
                            </div> 
                        </div>
                    </div> 
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <button mat-raised-button [ngClass]="tabChangecolor(1)">Configurations</button>
        </ng-template>
        <div class="tab-container p-2 mt-2">
            <div style="width:99%;">
                <div class="row">
                    <div class="col-4" *ngFor="let configuration of Allconfiguration">
                        <div class="lead-card">
                        <div class="row">
                                <h2>{{ configuration?.name }}</h2>
                                <p>Email: {{ configuration?.creatorEmail }}</p>
                                <p>Creator: {{ configuration?.creator }}</p>
                                <p>Description: {{ configuration?.description }}</p>
                                <p>Created: {{ configuration?.created |date:'dd-MMM-yyyy H:mm'}}</p>
                            </div>
                        </div> 
                    </div>
                </div> 
            </div>
            <span mat-fab style="background-color: transparent !important;" class="fab-table-group bottom_fab_2">
                <button id="add-button2"  mat-fab (click)="showConfigurationpopup()">
                    <mat-icon style="color: white;">add</mat-icon>
                </button>
            </span>
        </div>
    </mat-tab>
</mat-tab-group>




