import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmailComponent } from 'src/app/pages/emails/emails.component';
import { ApiServiceService } from 'src/app/service/api-service.service';
import { CreateAreChooseTageComponent } from '../create-are-choose-tage/create-are-choose-tage.component';
import { CommanForAllComponent } from '../comman-for-all/comman-for-all.component';
import { ShowTemplateViewComponent } from '../show-template-view/show-template-view.component';
import { CreateProjectComponent } from '../create-project/create-project.component';

@Component({
  selector: 'app-create-mail-model-campaign',
  templateUrl: './create-mail-model-campaign.component.html',
  styleUrls: ['./create-mail-model-campaign.component.scss'],
})
export class CreateMailModelCampaignComponent implements OnInit {
  Leadscorestatus = false;
  Form: FormGroup;
  AllLinksDD: any;
  RootImmediate = '1';
  SeletedMedia = 1;
  seletedfileName = null;
  GetAllSourcesList: any;
  GetSMTPProviderDDList: any;
  file: any;
  SourceCount = 0;
  RootImmediatetemplate: any;
  IncludeOrExclude = '1';
  GetSourceDetails: any;
  showfileview = false;
  htmlContent: string = '';
  AllLeadscore: any;
  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private loginservice: ApiServiceService,
    private snackBar: MatSnackBar,
    private matDialogRef: MatDialogRef<CreateMailModelCampaignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public datepipe: DatePipe
  ) {
    this.Form = this.formBuilder.group({
      SelectedProject: [''],
      Leadscoreid: [''],
      Leadscorestatus: [false],
      CampaignId: [''],
      SMTPConfigId: [1],
      SourceId: [''],
      Sender: ['MINSON Sebastien'],
      Subject: [''],
      LaunchDate: [],
      LaunchTime: ['00:00:00'],
      TargetCount: [''],
      EmailGroupOrDomain: ['sebastien.minson@planningandme.com'],
      templateId: [null],
      templatename: [null],
      UnSubscribeLink: [''],
      RootImmediate: ['1'],
      IncludeOrExclude: ['1'],
      SelectMediaType: [1],
      IsTargetEnabled: [false],
      AddTags: this.formBuilder.array([]),
      CustomTempValues: [null],
    });
  }
  ngOnInit(): void {
    this.getAllLeadscore();
    console.log(this.data);
    this.Form.get('CampaignId').setValue(this.data?.campaignId);
    this.Form.get('SelectedProject').setValue(this.data?.SelectedProject);
    this.loginservice.GetAllDDSource().subscribe((result: any) => {
      if (result) {
        this.GetAllSourcesList = result;
      }
    });
    this.loginservice.GetSMTPProviderDD().subscribe((result) => {
      if (result) {
        this.GetSMTPProviderDDList = result;
      }
    });
  }

  getAllLeadscore() {
    this.loginservice.getLeadscore(this.data?.SelectedProject).subscribe((result: any) => {
      if (result) {
        this.AllLeadscore = result;
      }
    });
  }

  AddTags(): FormArray {
    return this.Form.get('AddTags') as FormArray;
  }
  newFile(value): FormGroup {
    return this.formBuilder.group({
      fieldName1: [value.name],
      fieldName: [value.name.fieldName],
      fieldValue: [value.value],
    });
  }

  CreateAreChooseTage() {
    const CreateAreChooseTage = this.dialog.open(CreateAreChooseTageComponent, {
      disableClose: true,
      width: '400px',
      data: { SourceId: this.Form.value.SourceId, screenType: 'CreateTage' },
    });
    CreateAreChooseTage.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
        this.AddTags().push(this.newFile(result));
      }
    });
  }

  Removecondictuon(i: number) {
    this.AddTags().removeAt(i);
  }

  GetSourceCount(value) {
    this.GetSourceDetails = value;
    this.SourceCount = value.targetCount;
    this.Form.get('TargetCount').setValue(value.targetCount);
    console.log(value);
  }

  onSelectforfile(e) {
    this.file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result as string;
      this.htmlContent = result;
    };
    reader.readAsText(e.target.files[0]);
    this.showfileview = true;
  }

  showfileviewhtml() {
    this.ShowTemplateView();
  }

  loadHTMLFile(filePath: string): Promise<string> {
    return fetch(filePath).then((response) => response.text());
  }

  onSubmit() {
    console.log(this.Form.value);
    const formData = new FormData();
    formData.append('RootImmediate',this.Form.value.RootImmediate == '1' ? 'true' : 'false');
    formData.append('CampaignId', this.Form.value.CampaignId);
    formData.append('SMTPConfigId', this.Form.value.SMTPConfigId);
    formData.append('SourceId', this.Form.value.SourceId);
    formData.append('Sender', this.Form.value.Sender);
    formData.append('Subject', this.Form.value.Subject);
    formData.append('EmailGroupOrDomain', this.Form.value.EmailGroupOrDomain);
    formData.append('TargetCount', this.Form.value.TargetCount);
    formData.append('LeadScoreConfigId', this.Form.value.Leadscoreid);
    formData.append('IncludeOrExclude',this.Form.value.IncludeOrExclude == 1 ? 'true' : 'false');

    if (this.Form.value.templateId != null &&this.Form.value.templatename != null&&this.SeletedMedia==2) {
      formData.append('TemplateId', this.Form.value.templateId);
      formData.append('TempName', this.Form.value.templatename);
    } else {
      formData.append('EmailHtmlTemplate', this.file);
    }

    if (this.Form.value.RootImmediate == '2') {
      formData.append(
        'LaunchDate',
        this.datepipe.transform(this.Form.value.LaunchDate, 'yyyy/MM/dd')
      );
      formData.append('LaunchTime', this.Form.value.LaunchTime);
    }
    if (this.Form.value.AddTags.lenght != 0) {
      this.Form.value.AddTags.forEach((element, index) => {
        formData.append(
          'filtervalues[' + index + '].' + 'columnName',
          element.fieldName1.fieldName
        );
        formData.append(
          'filtervalues[' + index + '].' + 'tagColumnId',
          element.fieldName1.id
        );
        formData.append(
          'filtervalues[' + index + '].' + 'tagValue',
          element.fieldValue
        );
      });
    }
    if (this.Form.value.CustomTempValues != null&&this.Form.value.CustomTempValues.length!=0) {
      this.Form.value.CustomTempValues.forEach((element, index) => {
        formData.append(
          'CustomTempValues[' + index + '].' + 'columnName',
          element.tagColumnId.fieldName
        );
        formData.append(
          'CustomTempValues[' + index + '].' + 'tagColumnId',
          element.tagColumnId.id
        );
        formData.append(
          'CustomTempValues[' + index + '].' + 'replaceString',
          element.selectedText
        );
      });
    }
    this.loginservice.CreateemailModel(formData).subscribe(
      (result) => {
        if (result) {
          this.TestEmail();
          this.snackBar.open('Campaign Model created successfully', '', {
            duration: 3000,
          });
        }
      },
      (error) => {
        if (error.status == 200) {
          this.TestEmail();
          this.snackBar.open('Campaign Model created successfully', '', {
            duration: 3000,
          });
        } else {
          this.snackBar.open('Campaign Model created Faild', '', {
            duration: 3000,
          });
        }
      }
    );
  }

  RootCanpaign() {
    const RootCanpaign = this.dialog.open(CommanForAllComponent, {
      disableClose: true,
      data: {
        CampaignId: this.Form.value.CampaignId,
        ScreenType: 'Rootcampaign',
      },
    });
    RootCanpaign.afterClosed().subscribe((result) => {
      console.log(result);
      if (result) {
        this.RootImmediatebutton();
      }
    });
  }

  FromLocal(){
    this.Form.get('templateId').setValue(null);
    this.Form.get('templatename').setValue(null);
  }

  FromMediaLibrary() {
    const FromMediaLibrary = this.dialog.open(CommanForAllComponent, {
      disableClose: true,
      data: {
        CampaignId: this.Form.value.SelectedProject,
        ScreenType: 'FromMediaLibrary',
      },
    });
    FromMediaLibrary.afterClosed().subscribe((result) => {
      console.log(result);
      if (result.templateId) {
        this.seletedfileName = result.htmlFileName;
        this.Form.get('templateId').setValue(result.templateId);
        this.Form.get('templatename').setValue(result.htmlFileName);
        this.loginservice.GetHtmlTemplateContent(result.templateId).subscribe(
          (data: any) => {
            if (data) {
              this.htmlContent = data;
              console.log(result);
            }
          },
          (error) => {
            if (error.status == 200) {
              this.htmlContent = error.error.text;
              console.log(error.error.text);
            }
          }
        );
      }
    });
  }

  TestEmail() {
    const TestEmail = this.dialog.open(CommanForAllComponent, {
      disableClose: true,
      width: '500px',
      data: { CampaignId: this.Form.value.CampaignId, ScreenType: 'TestEmail' },
    });
    TestEmail.afterClosed().subscribe((result) => {
      const testemail = result.split(',');
      if (testemail.lenght != 0) {
        const formData = new FormData();
        testemail.forEach((element, index) => {
          formData.append('TestEmails[' + index + ']', element);
        });
        formData.append('campaignId', this.Form.value.CampaignId);
        this.loginservice
          .RootCampaignWithTestEmails(formData)
          .subscribe((result) => {
            if (result) {
              console.log(result);
              if (this.Form.value.RootImmediate == '1') {
                this.RootCanpaign();
              }
            }
          });
      } else {
        if (this.Form.value.RootImmediate == '1') {
          this.RootCanpaign();
        }
      }
    });
  }

  ListOfEmails() {
    this.dialog.open(EmailComponent, {
      disableClose: true,
      data: { data: this.GetSourceDetails },
    });
  }

  ShowTemplateView() {
    const ShowTemplateView = this.dialog.open(ShowTemplateViewComponent, {
      width: '100%',
      disableClose: true,
      data: {
        htmlContent: this.htmlContent,
        SourceId: this.Form.value.SourceId,
      },
    });
    ShowTemplateView.afterClosed().subscribe((result) => {
      console.log(result);
      console.log(result);
      if (result.lenght != 0) {
        this.Form.get('CustomTempValues').setValue(result);
      }
    });
  }

  CreateLeadscore() {
    const CreateProject = this.dialog.open(CreateProjectComponent, {
      disableClose: true,
      width: '500px',
      data: {
        SelectedProjectId: this.Form.value.SelectedProject,
        screenType: 'CreateLeadscore',
      },
    });
    CreateProject.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
        const formData = new FormData();
        formData.append('ProjectId', result.SelectedProjectId);
        formData.append('ConfigName', result.ConfigName);
        formData.append('OpenScore', result.OpenScore);
        formData.append('ClickScore', result.ClickScore);
        formData.append('FormSubmissionScore', result.FormSubmissionScore);
        formData.append('UnsubscribeScore', result.UnsubscribeScore);
        formData.append('BouncesScore', result.BouncesScore);
        if (result.Key_PageVisitCredits.lenght != 0) {
          result.Key_PageVisitCredits.forEach((element, index) => {
            formData.append(
              'TestEmails[' + index + '].pageCode',
              element.pageCode
            );
            formData.append('TestEmails[' + index + '].score', element.score);
          });
        }
        this.loginservice.CreateLeadscore(formData).subscribe(
          (result) => {
            if (result) {
              this.getAllLeadscore();
            }
          },
          (error) => {
            if ((error.status = 200)) {
              this.getAllLeadscore();
            }
          }
        );
      }
    });
  }

  RootImmediatebutton() {
    const formData = new FormData();
    formData.append('campaignId', this.Form.value.CampaignId);
    this.loginservice.RootCampaign(formData).subscribe(
      (result) => {
        if (result) {
          this.snackBar.open('Root Campaign successfully', '', {
            duration: 3000,
          });
          this.matDialogRef.close(true);
        }
      },
      (error) => {
        if (error.status == 200) {
          this.matDialogRef.close(true);
          this.snackBar.open('Root Campaign successfully', '', {
            duration: 3000,
          });
        } else {
          this.snackBar.open('Root Campaign Faild', '', { duration: 3000 });
        }
      }
    );
  }
}
