import { Component } from "@angular/core";

@Component({
  selector: "input-socket",
  templateUrl: "./input-socket.component.html",
  styleUrls: ["./input-socket.component.scss"]
})
export class InputSocketComponent {
  constructor() {}
}
