import { Component, OnInit, Inject } from '@angular/core';
import { inject } from '@angular/core/testing';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiServiceService } from 'src/app/service/api-service.service';
import { saveAs } from 'file-saver';
import { CommanForAllComponent } from '../comman-for-all/comman-for-all.component';

@Component({
  selector: 'app-create-email',
  templateUrl: './create-email.component.html',
  styleUrls: ['./create-email.component.css'],
})
export class CreateEmailComponent {
  Form: FormGroup;
  AllLinksDD: any;
  file: any;
  sourceDetails: any;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private loginservice: ApiServiceService,
    private matDialogRef: MatDialogRef<CreateEmailComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.Form = this.formBuilder.group({
      email: ['', [Validators.required]],
      sourcename: ['', [Validators.required]],
      SourceId: ['', [Validators.required]],
      fileinput: [],
      lastName: [''],
      firstName: [''],
      phoneNumber: [''],
      AddTags: this.formBuilder.array([]), 
    });
  }
  ngOnInit(): void {
    console.log(this.data.data);
    if (this.data.data) {
      this.sourceDetails = this.data.data;
      this.Form.get('sourcename')?.setValue(this.sourceDetails.sourceName);
      this.Form.get('SourceId')?.setValue(this.sourceDetails.sourceId);
      this.loginservice
        .GetAllTagsOfSource(this.sourceDetails.sourceId)
        .subscribe((result: any) => {
          if (result) {
            result.forEach((element, index) => {
              this.Addcondictuon();
              this.AddTags().at(index).get('fieldName').setValue(element);
            });
            console.log(result);
          }
        });
    }
  }

  AddTags(): FormArray {
    return this.Form.get('AddTags') as FormArray;
  }
  newFile(): FormGroup {
    return this.formBuilder.group({
      fieldName: [''],
      fieldValue: [''],
    });
  }

  Addcondictuon() {
    this.AddTags().push(this.newFile());
  }
  Removecondictuon(i: number) {
    this.AddTags().removeAt(i);
  }
  // handleFileInput
  handleFileInput(event: any) {
    this.file = event.target.files[0];
    if(event.target.files[0]){
      this.importfiledata()
    }
  }



  importfiledata() {
    const RootCanpaign = this.dialog.open(CommanForAllComponent, {
      disableClose: true,
      data: {ScreenType: 'ImportData'},
    });
    RootCanpaign.afterClosed().subscribe((result) => {
      console.log(result);
      if (result) {
        this.UploadImportFile();
      }
    });
  }

  UploadImportFile() {
    const formData = new FormData();
    formData.append('file', this.file);
    this.loginservice.UploadImportFile(formData,this.sourceDetails.sourceId).subscribe(
      (result: any) => {
        if (result) {
          this.snackBar.open('ImportFile done', '', { duration: 3000 });
          this.matDialogRef.close(true);
        }
      },
      (error) => {
        if (error.status == 200) {
          this.snackBar.open('ImportFile done', '', { duration: 3000 });
          this.matDialogRef.close(true);
        }
      }
    );
  }

  downloadTemplate() {
    this.loginservice
      .DownloadImportTemplate(this.sourceDetails.sourceId)
      .subscribe((response) => {
        let blob: Blob = response.body as Blob;
        let a = document.createElement('a');
        a.download = 'ContactImport';
        a.href = window.URL.createObjectURL(blob);
        a.click();
      });
  }

  onSubmit() {
    console.log(this.Form.value);
    const data = {
      sourceId: this.Form.value.SourceId,
      email: this.Form.value.email,
      lastName: this.Form.value.lastName,
      firstName: this.Form.value.firstName,
      tagViewModels: this.Form.value.AddTags,
    };
    this.loginservice.CreateEmail(data).subscribe(
      (result: any) => {
        if (result) {
          this.snackBar.open('Email createrd', '', { duration: 3000 });
          this.matDialogRef.close(true);
        }
      },
      (error) => {
        if (error.status == 200) {
          this.snackBar.open('Email createrd', '', { duration: 3000 });
          this.matDialogRef.close(true);
        }
      }
    );
  }
}
