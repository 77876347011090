import { Component, OnInit, Inject } from '@angular/core';
import { inject } from '@angular/core/testing';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiServiceService } from 'src/app/service/api-service.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-create-source',
  templateUrl: './create-source.component.html',
  styleUrls: ['./create-source.component.css']
})
export class CreateSourceComponent {

  Form: FormGroup;
  AllLinksDD: any;
  file: any;
  fileToUpload: any;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private loginservice: ApiServiceService,
    private matDialogRef: MatDialogRef<CreateSourceComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.Form = this.formBuilder.group({
      Description: [''],
      Keywords: [''],
      sourcename: ['', [Validators.required]],
    });
  }
  ngOnInit(): void {
  }



  onSubmit() {
    const data={
      SourceName:this.Form.value.sourcename,
      Description:this.Form.value.Description,
      Keywords:this.Form.value.Keywords,
    }
    // const formData = new FormData();
    // formData.append('SourceName', this.Form.value.sourcename);
    // formData.append('Description', this.Form.value.Description);
    // formData.append('Keywords', this.Form.value.Keywords);
    this.loginservice.CreateSource(data).subscribe( (result: any) => {
        if (result) {
          this.snackBar.open('Source createrd', '', { duration: 3000 });
          this.matDialogRef.close(true);
        }
      },
      (error) => {
        if (error.status == 200) {
          this.snackBar.open('Source createrd', '', { duration: 3000 });
          this.matDialogRef.close(true);
        }
      }
    );
  }
}
