import { Component } from "@angular/core";
import { Node, Path, Position, Size } from "./models/model";
import { IOUtils } from "./utils/io.utils";
import { MatDialog } from "@angular/material/dialog";
import { AutomationActionsListComponent } from "./components/automation-actions-list/automation-actions-list.component";
import { position } from "html2canvas/dist/types/css/property-descriptors/position";


@Component({
  selector: 'app-lead-automation',
  templateUrl: './lead-automation.component.html',
  styleUrls: ['./lead-automation.component.scss'],
})

export class LeadAutomationComponent {
  nodes: Node[];
  paths: Path[];
  zoomFactor: number = 1.0; // Initial zoom factor

  private nodeSize: Size = { width:50, height:50 };
  private nodePositions: { [id: number]: Position } = {};
  private newNode: any ;

  constructor(
    private dialog: MatDialog,
  ) {
    this.nodePositions = { 1:{ x: 600, y: -500 }};
    this.nodes = [];
    this.paths = [];
  }

  zoomIn() {
    this.zoomFactor += 0.1; // Increase zoom factor by 10%
  }

  zoomOut() {
    this.zoomFactor -= 0.1; // Decrease zoom factor by 10%
  }

  handleChangeNodePosition(event: { id: number; position: Position }) {
    this.nodePositions[event.id] = event.position;
    this.nodes.forEach(element=>{
      if(element.id==Number(event.id)){
        // element.initialPosition=event.position
      }
    })
  }

  getD(path: Path) {
    const startPosition = this.getNodePosition(path.from, "OUTPUT");
    const endPosition = this.getNodePosition(path.to, "INPUT");
    return (
      startPosition && endPosition && IOUtils.getD(startPosition, endPosition)
    );
  }

  private getNodePosition(
    nodeId: number,
    socketType: "INPUT" | "OUTPUT"
  ): Position | undefined {
    const position = this.nodePositions[nodeId];
    if (!position) {
      return undefined;
    }
    if (socketType === "INPUT") {
      return IOUtils.setOffset(position, { x: 0, y: this.nodeSize.height / 2 });
    }
    return IOUtils.setOffset(position, {
      x: this.nodeSize.width,
      y: this.nodeSize.height / 2
    });
  }

  addNewPopupItem(event:any) {
    this.ActionsList(event,event.type)
  }


  Deletenodepotiont(event:any){
    console.log(event)
  }
  Updatenodepotiont(event:any){
    console.log(event)
  }



  ActionsList(event,type) {
    console.log(event)
    const ActionsList = this.dialog.open(AutomationActionsListComponent, {
      disableClose: true,
      width:'500px',
      data:{plancreate:this.nodes.length==0?true:false,Actions:this.nodes.length>1?true:false,type:type}
    });
    ActionsList.afterClosed().subscribe(result => {
      if(result){
        console.log(result)
        console.log(event)
        console.log(event.node)
         this.paths.push({ from: event.node.id, to:this.nodes.length+1})
         const index =this.nodes.length+1
        //  this.nodePositions[index]={ x:event.node.initialPosition.x+60, y:event.node.initialPosition.y+150 }
         this.nodePositions[index]={ x: 600, y: -500 }
         this.nodes.push({
            id: this.nodes.length+1,
            color:result?.color,
            data:result?.data,
            createplan:result.createplan,
            scenarios:result?.scenarios,
            Actions:result?.Actions,
            type: result?.type,
            outputSocket: {},
            initialPosition: this.nodePositions[this.nodes.length+1]
          })
         this.nodePositions[event.id] = event.position;
        }
        console.log(this.nodes)
    });
  }



  startworkflow(){
    const ActionsList = this.dialog.open(AutomationActionsListComponent, {
      disableClose: true,
      width:'500px',
      data:{plancreate:this.nodes.length==0?true:false,Actions:this.nodes.length>1?true:false ,type:'CreatePlan'}
    });
    ActionsList.afterClosed().subscribe(result => {
      if(result){
        console.log(result)
         this.paths.push({ from: 0, to:1})
         this.nodes.push({
            id: 1,
            color:result?.color,
            data:result?.data,
            createplan:result.createplan,
            scenarios:result?.scenarios,
            Actions:result?.Actions,
            type: result?.type,
            outputSocket: {},
            initialPosition: this.nodePositions[1]
          })
        }
    });
  }


  getFinalflowchart(){
    this.nodes.forEach((element,index)=>{
      element.initialPosition=this.nodePositions[index+1]
    })
    console.log(this.nodes)
    console.log(this.nodePositions)
  }
}