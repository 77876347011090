
<div >
	<div class="node" cdkDrag (cdkDragMoved)="handleDragMoved($event)" [cdkDragFreeDragPosition]="node.initialPosition">
		<!-- <input-socket *ngIf="node.inputSocket"></input-socket> -->
		<div class="circle">
		<div style="color: white;" [ngStyle]="{'background-color': node.color}">
			<div class="row p-1">
				<div class="col-2">
					<img *ngIf="node.type=='CreatePlan'" style="color: white !important;" class="titleimagestyle" src="../../../../../../assets/svg-icons/plan.svg" alt="">
					<img *ngIf="node.type=='CreateContiction'" class="titleimagestyle" src="../../../../../../assets/svg-icons/condition.svg" alt="">
					<img *ngIf="node.type=='CreateAction'" class="titleimagestyle" src="../../../../../../assets/svg-icons/Action.svg" alt="">
					</div>
				<div class="col-8">
					<input type="text" placeholder="Title" style="display: flex;width:99%;border: none;font-size:16px;background-color: transparent;color: white !important;" [(ngModel)]="node.data.Name"[value]="node.data.Name">
				</div>
				<div class="col-2">
						<mat-icon style="color: white;" [matMenuTriggerFor]="menu">more_vert</mat-icon>
				</div>
			</div>
		</div><br>
			<div class="row p-1 m-1">
					<input type="text" placeholder="Objective" style="display: flex;width: 99%;border: none;font-size:18px;background-color: transparent;" [(ngModel)]="node.data.Description"[value]="node.data.Description">
			</div>
		</div>
		<!-- <output-socket (click)="fileInput1.click()" *ngIf="node.outputSocket"></output-socket> -->
		<mat-menu #menu="matMenu">
			<button mat-menu-item #fileInput1 (click)="openPopup(node,'CreateContiction')">add Contiction</button>
			<button mat-menu-item #fileInput1 (click)="openPopup(node,'CreateAction')">add Action</button>
			<button mat-menu-item (click)="Updatenodepotiont(node)">Update</button>
			<button mat-menu-item (click)="Deletenodepotiont(node)">Delete</button>
		</mat-menu>
	</div>
</div>


