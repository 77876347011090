import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DeleteDialogComponent } from 'src/app/components/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  // HeaderTitle: any

  @Input() headerTitle: any

  ngOnInit(): void {
    console.log(this.headerTitle)
  }

  constructor(
    private router: Router,
    private dialog: MatDialog,
  ) {

  } 



  logout(){
   this.dialog.open(DeleteDialogComponent, {
      height: '200px',
      width: '300px',
      data: {data:'',ScreenType:'logout'}
      
    });
  }





}
