
<!-- TestEmail  -->
<ng-container *ngIf="screenTyle=='TestEmail'">
    <div class="d-flex flex-row align-items-center modal-header">
        <mat-icon mat-dialog-close>keyboard_backspace</mat-icon>
        <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
            <span>Do you wish to make a test ...?</span>
        </h2>
    </div>
    <mat-dialog-content class="mr-0 ml-0">
        <form [formGroup]="Form">
            <div class="row ">
                    <mat-form-field appearance="outline" class="w-100 h-100">
                        <mat-label><mat-icon>date_range</mat-icon> Emails</mat-label>
                        <textarea matInput formControlName="TsetEmails"></textarea>
                    </mat-form-field>
                </div> 
        </form>
            </mat-dialog-content>
            <mat-dialog-actions align="end" class="my-1">
                <button mat-raised-button (click)="Testemailbutton()">Skip</button>
                <button mat-raised-button class="b-black" (click)="Testemailbutton()">Make Test</button>
            </mat-dialog-actions>
</ng-container>


<!-- ImportData -->
<ng-container *ngIf="screenTyle=='ImportData'">
    <div class="d-flex flex-row align-items-center modal-header">
        <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">Confirmation</h2>
        <mat-icon mat-dialog-close>close</mat-icon>
    </div>
<div class="row">
    <div class="container">
        <div class="row justify-content-start">
            <h2 style="text-align: center;width: 100%;">Do you wish to Import Data ... ? </h2>
        </div>
    </div>
    <mat-dialog-actions align="end" class="my-1">
        <button mat-raised-button (click)="ImportData(false)">No</button>
        <button mat-raised-button class="b-black" (click)="ImportData(true)">Yes</button>
    </mat-dialog-actions>
</div>
</ng-container>


<!-- Rootcampaign  -->
<ng-container *ngIf="screenTyle=='Rootcampaign'">
    <div class="d-flex flex-row align-items-center modal-header">
        <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">Confirmation</h2>
        <mat-icon mat-dialog-close>close</mat-icon>
    </div>
<mat-dialog-content class="mr-0 ml-0">
<div class="row">
    <div class="container">
        <div class="row justify-content-start">
            <h2 style="text-align: center;width: 100%;">Do you wish to Root the Campaign ... ? </h2>
        </div>
    </div>
</div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="row m-0 p-0">
        <div class="col-6">
            <button mat-raised-button >Save as Draft</button>
        </div>
        <div class="col-6">
            <button mat-raised-button (click)="RootImmediatebutton(false)">No</button>
            <button mat-raised-button class="b-black" (click)="RootImmediatebutton(true)">Yes</button>
        </div>
    </div>
</mat-dialog-actions>
</ng-container>


<!-- FromMediaLibrary  -->
<ng-container *ngIf="screenTyle=='FromMediaLibrary'">
    <div class="d-flex flex-row align-items-center modal-header">
        <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">Media Library</h2>
        <mat-icon mat-dialog-close>close</mat-icon>
    </div>
    <mat-dialog-content class="mr-0 ml-0">
<div class="row">
    <div class="container">
        <div class="row justify-content-start">
          <div class="col-4" *ngFor="let x of FromMediaLibrarylist" (click)="FromMediaLibrary(x)" style="cursor: pointer;">
          <div>
            <img style="width: 100%;
            height: 300px;
            object-fit: contain;
            overflow-y: auto;" 
            onError="this.src='assets/images/img/template.jpg'"
            [src]="x?.pnGFileUrl  ? x.pnGFileUrl :'assets/images/img/template.jpg'">
            <p style="width: 100%;text-align: start;margin-top: 5px;">{{x.htmlFileName}}</p>
            <p style="width: 100%;text-align: start;"><b>Created :</b>{{x.created|date}}</p>
          </div>
          </div>
        </div>
    </div>
</div>
</mat-dialog-content>
</ng-container>

<!-- LeedAutomation  -->
<ng-container *ngIf="screenTyle=='LeedAutomation'">
    <div class="d-flex flex-row align-items-center modal-header">
        <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">Actions</h2>
        <mat-icon mat-dialog-close>close</mat-icon>
    </div>
    <mat-dialog-content class="mr-0 ml-0">
<div class="row">
    <div class="container">
        <div class="row justify-content-start">
          <div class="col-4" *ngFor="let x of leedAitomationList" (click)="actionvalue(x.name)" style="cursor: pointer;">
          <div>
            <p style="width: 100%;text-align: start;margin-top: 5px;">{{x.name}}</p>
          </div>
          </div>
        </div>
    </div>
</div>
</mat-dialog-content>
</ng-container>