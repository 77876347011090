import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ApiServiceService } from 'src/app/service/api-service.service';

@Component({
  selector: 'app-map-details',
  templateUrl: './map-details.component.html',
  styleUrls: ['./map-details.component.scss'],
})
export class MapDetailsComponent implements OnInit {
  mapDetails: any;
  screenType = '';
  panelOpenState = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private loginservice: ApiServiceService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    console.log(this.data)
    if (this.data) {
      this.screenType = this.data.screenType;
      if (this.screenType == 'viewDetails') {
        if(this.data?.overview===true){
          this.loginservice.GetCustomerJournyvoerviwe( this.data?.data?.recipientId==0? this.data?.data?.visitorId: this.data?.data?.recipientId.toString(),this.data?.projectId,this.data?.data?.recipientId==0?false:true ).subscribe((result) => {
            if (result) {
              this.mapDetails = result;
            }
          });
        }else{
          this.loginservice.GetCustomerJournycomp( this.data?.data?.recipientId==0? this.data?.data?.visitorId: this.data?.data?.recipientId.toString(),this.data?.campaignId,this.data?.data?.recipientId.toString()==0?false:true ).subscribe((result) => {
            if (result) {
              this.mapDetails = result;
            }
          });
        }
      }else if( this.screenType== 'overAllcampaignstatus'){
        this.mapDetails = this.data.data;
      } else {
        this.mapDetails = this.data.data;
      }
    }
    console.log(this.data);
  }




  showmapclickdetails(value){
    console.log(value)
    this.mapDetailsview(value, 'viewDetails');

  }



  mapDetailsview(data, screenType) {
    const mapDetails = this.dialog.open(MapDetailsComponent, {
      width: '500px',
      data: {
        data: data,
        campaignId: this.data?.campaignId,
        projectId:  this.data?.projectId,
        screenType: screenType,
        overview: this.data?.overview,
      },
    });
    mapDetails.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

}
