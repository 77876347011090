<div class="d-flex flex-row align-items-center modal-header">
    <mat-icon mat-dialog-close>keyboard_backspace</mat-icon>
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <span>  Create Configuration</span>
    </h2>
</div>
<mat-dialog-content class="mr-0 ml-0"> 
    <form [formGroup]="Form">
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>Configuration Name </mat-label>
                <input matInput  formControlName="Configurationname">
            </mat-form-field>
        </div>
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <input matInput  formControlName="description">
            </mat-form-field>
        </div>
        <div class="row" style="background-color: rgb(202, 198, 198);color: black;padding: 10px 4px 2px;">
            <div class="col-10">
                Lead Score Conditions
            </div>
            <div class="col-2">
                <mat-icon style="color: black !important;" (click)="Addnewcondictuon()">add_box</mat-icon>
            </div>
        </div>
        <div formArrayName="leadScoreConditions">
            <div class="row"
                *ngFor="let file of getCondition().controls; let i=index"
                [formGroupName]="i">
                <div class="row conditionsstyle">
                    <div class="col-3">
                        <mat-form-field class="w-100" appearance="legacy">
                            <mat-label>Parameter</mat-label>
                            <mat-select formControlName="conditionParameter">
                                <mat-option [value]="x.columnCode" *ngFor="let x of conditioncolumns">{{x.columnName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field class="w-100" appearance="legacy">
                            <mat-label>Comparison</mat-label>
                            <mat-select formControlName="conditionComparison">
                                <mat-option [value]="x.v1" *ngFor="let x of AllOption">{{x.v2}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-3">
                        <mat-form-field appearance="legacy" class="w-100">
                            <mat-label>Value<span class="danger"></span></mat-label>
                            <input matInput type="text" formControlName="value">
                        </mat-form-field>
                    </div>
                    <div class="col-2">
                        <mat-form-field appearance="legacy" class="w-100">
                            <mat-label>score<span class="danger"></span></mat-label>
                            <input matInput type="number" formControlName="scorePoint">
                        </mat-form-field>
                    </div>
                    <div class="col-1">
                        <mat-icon style="margin-top: 10px;color:black;" (click)="Removecondictuon(i)">delete</mat-icon>
                    </div>
                </div>
            </div>
        </div> 
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="my-1">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button class="b-blue" [disabled]="!Form.valid" (click)="onSubmit()">Apply</button>
</mat-dialog-actions>

