import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiServiceService } from 'src/app/service/api-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-automation-actions-list',
  templateUrl: './automation-actions-list.component.html',
  styleUrls: ['./automation-actions-list.component.scss']
})
export class AutomationActionsListComponent implements OnInit {
  tabnumber: number=0
  Form: FormGroup;
  Form1: FormGroup;
  Form2: FormGroup;
  createplan:boolean=true
  scenarios:boolean=true
  Actions:boolean=true
  screnType=''
    // auto chip for keyword
    separatorKeysCodes: number[] = [ENTER, COMMA];
    keywordlist: string[] = [];
  constructor(
    private matDialogRef: MatDialogRef<AutomationActionsListComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private loginservice: ApiServiceService,
    private snackBar: MatSnackBar,

  ) {
    this.Form = this.formBuilder.group({
      Description : [''],
      Active : [true],
      Name: ['',[Validators.required]],
    });
    this.Form1 = this.formBuilder.group({
      Description : [''],
      Active : [true],
      Name: ['',[Validators.required]],
    });
    this.Form2 = this.formBuilder.group({
      Description : [''],
      Active : [true],
      Name: ['',[Validators.required]],
    });
   }

  ngOnInit(): void {
    console.log(this.data)
    this.createplan=this.data.plancreate
    this.Actions=this.data.Actions
    this.screnType=this.data.type
  }


  Submit(){
    const data={
      color:'green',
      data:this.Form.value,
      createplan:true,
      scenarios:false,
      Actions:false,
      type:'CreatePlan'
    }
    this.matDialogRef.close(data)

  }
  submit1(){
    const data={
      color:'Yellow',
      data:this.Form1.value,
      createplan:this.createplan,
      scenarios:this.scenarios,
      Actions:this.Actions,
      type:'CreateContiction'
    }
    this.matDialogRef.close(data)
  }

  submit2(){
    const data={
      color:'blue',
      data:this.Form2.value,
      createplan:this.createplan,
      scenarios:this.scenarios,
      Actions:this.Actions,
      type:'CreateAction'
    }
    this.matDialogRef.close(data)
  }

  tabChange(tabIndex: number): void {
    this.tabnumber = tabIndex;
  }





 

  Deleteaction(value){
    console.log(value)
  }








    // ---------------- 
  // auto chip for keyword 
  // ----------- 
  add(event: MatChipInputEvent) {
    const value = (event.value || '').trim();
    if (value) { this.keywordlist.push(value); }
    // Clear the input value
    event.input.value = ''

  }
  remove(value: string) {
    const index = this.keywordlist.indexOf(value);
    if (index >= 0) {
      this.keywordlist.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent) {
    this.keywordlist.push(event.option.viewValue);
  }

}
