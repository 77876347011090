import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiServiceService } from 'src/app/service/api-service.service';
import { CreateAreChooseTageComponent } from '../create-are-choose-tage/create-are-choose-tage.component';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss']
})
export class CreateProjectComponent implements OnInit {
  ProjectName=''
  Screentype:any
  Form: FormGroup;
  Form1: FormGroup;
  GetAllpageCode:any;
b
  constructor(
    private matDialogRef: MatDialogRef<CreateProjectComponent>,
    private loginservice: ApiServiceService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.Form = this.formBuilder.group({
      ConfigName: ['',[Validators.required]],
      SelectedProjectId:[''],
      OpenScore: [''],
      ClickScore: [''],
      FormSubmissionScore: [''],
      UnsubscribeScore: [''],
      BouncesScore: [''],
      Key_PageVisitCredits: this.formBuilder.array([]),

    });
    this.Form1 = this.formBuilder.group({
      projectName: ['',[Validators.required]],
      baseUrl:['',[Validators.required]],
    });
  }


  AddTags(): FormArray {
    return this.Form.get('Key_PageVisitCredits') as FormArray;
  }
  newFile(): FormGroup {
    return this.formBuilder.group({
      pageCode: [],
      score: [],
    });
  }

  Removecondictuon(i: number) {
    this.AddTags().removeAt(i);
  }

  ngOnInit(): void {
    console.log(this.data)
    this.Screentype=this.data.screenType
    this.Form.get('SelectedProjectId').setValue(this.data.SelectedProjectId)
    if(this.data?.SelectedProjectId){
      this.loginservice.GetPageTitles(this.data?.SelectedProjectId).subscribe((result: any) => {
        if (result) {
          this.GetAllpageCode = result;
        }
      });
    }
    
  }


  CreateAreChooseTage() {
    this.AddTags().push(this.newFile());
  }




  onSubmit1(){
    let totalscore=this.Form.value.OpenScore+this.Form.value.ClickScore+this.Form.value.FormSubmissionScore
if(this.Form.value.Key_PageVisitCredits.lenght!=0){
  this.Form.value.Key_PageVisitCredits.forEach(element => {
    totalscore=totalscore+element.score
  });

  if(totalscore==100){
    this.matDialogRef.close(this.Form.value)
  }else{
window.alert('totalscore less then 100')
  }
}
  }

  onSubmit(){
    this.matDialogRef.close(this.Form1.value)
  }

}
