import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiServiceService } from 'src/app/service/api-service.service';
import { CreateMailModelCampaignComponent } from '../create-mail-model-campaign/create-mail-model-campaign.component';
import { CreateProjectComponent } from '../create-project/create-project.component';

@Component({
  selector: 'app-create-campaigns',
  templateUrl: './create-campaigns.component.html',
  styleUrls: ['./create-campaigns.component.css'],
})
export class CreateCampaignsComponent implements OnInit {
  Form: FormGroup;
  AllLinksDD: any;
  AllProjects: any;
  CampaignType = 1;
  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private loginservice: ApiServiceService,
    private snackBar: MatSnackBar,
    private matDialogRef: MatDialogRef<CreateCampaignsComponent>
  ) {
    this.Form = this.formBuilder.group({
      campaignname: ['', [Validators.required]],
      campaignId: [''],
      SelectedProject: [''],
      campaignType: ['', [Validators.required]],
      Discription:[''],
      linkId: [0],
      isTemplate: [false],
    });
  } 

  ngOnInit(): void {
    this.GetAllLinksDD();
    this.getAllProjects()
  }

  
  CreateProject(){
    const CreateProject = this.dialog.open(CreateProjectComponent, {
      disableClose: true,
      width: '500px',
      data:{screenType:'CreateProject'}
    });
    CreateProject.afterClosed().subscribe(result => {
      if(result){
        console.log(result)
        this.loginservice.CrateProject(result).subscribe((result: any) => {
          if (result) {
            this.getAllProjects()
          }
        });

      }
    });
  }



  getAllProjects() {
    this.loginservice.AllProjects().subscribe((result: any) => {
      if (result) {
        this.AllProjects = result;
      }
    });
  } 

  GetAllLinksDD() {
    this.loginservice.GetAllLinksDD().subscribe((result: any) => {
      if (result) {
        this.AllLinksDD = result;
      }
    });
  }

  onSubmit() {
    // this.CreateMailModelCampaign()


    var data;
    if (this.CampaignType == 0) {
      data = {
        campaignName: this.Form.value.campaignname,
        campaignType: this.Form.value.campaignType,
        projectId:this.Form.value.SelectedProject,
        linkId: this.Form.value.linkId,
        isTemplate: this.Form.value.isTemplate,
      };
    } else {
      data = {
        campaignName: this.Form.value.campaignname,
        campaignType: this.Form.value.campaignType,
        projectId:this.Form.value.SelectedProject,
        linkId: this.Form.value.linkId,
        isTemplate: this.Form.value.isTemplate,
      };
    }
    this.loginservice.CreateCampaign(data).subscribe((result: any) => {
      if (result) {
        this.Form.get('campaignId').setValue(result.campaignId)
        if (this.Form.value.campaignType == 0) {
          console.log(result.response);
        } else {
          this.CreateMailModelCampaign()
        }
      }
    });
  }




  CreateMailModelCampaign(){
    const createUserDialog = this.dialog.open(CreateMailModelCampaignComponent, {
      disableClose: true,
      width: '600px',
      data:this.Form.value
    });
    createUserDialog.afterClosed().subscribe(result => {
      if(result){
          this.matDialogRef.close(true)
      }
    });
  }




}
