
<ng-container *ngIf="screenType=='viewDetails'||screenType=='overAllcampaignstatus'">
    <div class="row align-items-center modal-header p-0 m-0">
        <div class="col-2"><mat-icon mat-dialog-close>keyboard_backspace</mat-icon></div>
        <div class="col-8">
            <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
                <span *ngIf="screenType=='viewDetails'"> Customer Journey</span>
                <span *ngIf="screenType=='overAllcampaignstatus'"> Campaign Status</span>
            </h2></div>
        <div class="col-2">
        </div>
    </div>
</ng-container>
<mat-dialog-content class="mr-0 ml-0">
  <!-- viewDetails  -->
<ng-container *ngIf="screenType=='viewDetails'">
    <section class="detail-wrapper">
        <mat-list>
            <mat-list-item >
                <div class="fw-600">Email : </div>
                <span class="ml-3 fw-600 color_grey">{{mapDetails?.email}} </span>
            </mat-list-item>
            <mat-list-item >
                <div class="fw-600">Country : </div>
                <span class="ml-3 fw-600 color_grey">{{mapDetails?.country}} </span>
            </mat-list-item>
            <mat-list-item >
                <div class="fw-600">city : </div>
                <span class="ml-3 fw-600 color_grey">{{mapDetails?.city}} </span>
            </mat-list-item>
            <mat-list-item >
                <div class="fw-600">continent : </div>
                <span class="ml-3 fw-600 color_grey"> {{mapDetails?.continent}}</span>
            </mat-list-item>
            <mat-list-item >
                <div class="fw-600">visit Count : </div>
                <span class="ml-3 fw-600 color_grey"> {{mapDetails?.visitCount}}</span>
            </mat-list-item>
            <mat-list-item >
                <div class="fw-600">visit Duration : </div>
                <span class="ml-3 fw-600 color_grey"> {{mapDetails?.visitDuration}}</span>
            </mat-list-item>
            <mat-list-item >
                <div class="fw-600">visit Ip : </div>
                <span class="ml-3 fw-600 color_grey"> {{mapDetails?.visitIp}}</span>
            </mat-list-item>
            <mat-list-item >
                <div class="fw-600">visitor Id : </div>
                <span class="ml-3 fw-600 color_grey"> {{mapDetails?.visitorId}}</span>
            </mat-list-item>
            <mat-list-item >
                <div class="fw-600">visit Local Time : </div>
                <span class="ml-3 fw-600 color_grey"> {{mapDetails?.visitLocalTime}}</span>
            </mat-list-item>
        </mat-list>
    </section>

    <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
            Customer Journey
            </mat-panel-title>
          </mat-expansion-panel-header>
         <div class="row" *ngFor="let x of mapDetails?.journydtls">
            timeDuration : {{x.timeDuration}}
            visit : {{x.visit}}
         </div>
        </mat-expansion-panel>
      </mat-accordion>
</ng-container>
<!-- TableDetails  -->
<ng-container *ngIf="screenType=='TableDetails'">
    <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Email</th>
            <th scope="col">Country</th>
            <th scope="col">visit Count</th> 
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let x of mapDetails" >
            <td (click)="showmapclickdetails(x)">{{x.email}}</td>
            <td (click)="showmapclickdetails(x)">{{x.country}}</td>
            <td (click)="showmapclickdetails(x)">{{x.visitCount}}</td>
          </tr>
        </tbody>
      </table>
</ng-container>
<!-- overAllcampaignstatus  -->
<ng-container *ngIf="screenType=='overAllcampaignstatus'">
    <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Email</th>
            <th scope="col">Sent</th> 
            <th scope="col">Delivered</th>
            <th scope="col">Opened</th> 
            <th scope="col">Clicked</th> 
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let x of mapDetails" >
            <td (click)="showmapclickdetails(x)">{{x.email}}</td>
            <td (click)="showmapclickdetails(x)">{{x.sent|date}}</td>
            <td (click)="showmapclickdetails(x)">{{x.delivered|date}}</td>
            <td (click)="showmapclickdetails(x)">{{x.openned|date}}</td>
            <td (click)="showmapclickdetails(x)">{{x.clicked|date}}</td>
          </tr>
        </tbody>
      </table>
</ng-container>
</mat-dialog-content>