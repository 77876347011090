<div class="d-flex flex-row align-items-center modal-header ">
    <mat-icon style="color:black;" mat-dialog-close>keyboard_backspace</mat-icon>
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <span> Create Campaign (1/2)</span>
    </h2>
</div>
<mat-dialog-content class="mr-0 ml-0">
    <form [formGroup]="Form">
        <div class="row "> 
            <div class="col-10">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label><mat-icon>folder</mat-icon> Select Project *</mat-label>
                <mat-select formControlName="SelectedProject">
                    <mat-option *ngFor="let x of AllProjects"
                        [value]="x.id">
                        <b>{{x.projectName}}</b>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            </div>
            <div class="col-2">
                <mat-icon style="margin-top: 10px;" (click)="CreateProject()">add_box</mat-icon>
            </div>
         
        </div>
        <div class="row ">
            <mat-form-field appearance="outline">
                    <mat-label><mat-icon>date_range</mat-icon> Campaign Type *</mat-label>
                <mat-select formControlName="campaignType" [(ngModel)]="CampaignType">
                    <mat-option  [value]="0"><img class="drowdownimage" src="../../../assets/svg-icons/link-svgrepo-com.svg"> <b>Link Campaign</b></mat-option>
                    <mat-option  [value]="1"><img class="drowdownimage" src="../../../assets/svg-icons/e-mail-svgrepo-com.svg">  <b>Email Campaign</b></mat-option>
                    <mat-option  [value]="2"><img class="drowdownimage" src="../../../assets/svg-icons/phone-svgrepo-com.svg">  <b>Phone Campaign</b></mat-option>
                </mat-select>
            </mat-form-field>
        </div>
   
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label><mat-icon>text_format</mat-icon> Campaign Name *</mat-label>
                <input matInput  formControlName="campaignname">
            </mat-form-field>
        </div>
        <div class="row " *ngIf="CampaignType==0">
            <mat-form-field>
                <mat-label><mat-icon>date_range</mat-icon> Select a Link</mat-label>
                <mat-select formControlName="linkId">
                    <mat-option *ngFor="let x of AllLinksDD" [value]="x.linkId">{{x.campaignName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label><mat-icon>subject</mat-icon> Discription*</mat-label>
                <input matInput  formControlName="Discription">
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="my-1">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button class="b-black" *ngIf="CampaignType!=1"  (click)="onSubmit()">Apply</button>
    <button mat-raised-button class="b-black" *ngIf="CampaignType==1" (click)="onSubmit()">Next</button>
</mat-dialog-actions>