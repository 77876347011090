<ng-container *ngIf="screenType=='deleteUtmLink'">
  <h1 mat-dialog-title style="text-align: center;">Conformation</h1>
<div mat-dialog-content>
  <h4> Do you want to delete ....?</h4>
</div>
  <div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button (click)="deleteUtm()" >Ok</button>
  </div>
</ng-container>

<ng-container *ngIf="screenType=='deleteemail'">
  <h1 mat-dialog-title style="text-align: center;">Conformation</h1>
<div mat-dialog-content>
  <h4> Do you want to delete ....?</h4>
</div>
    <div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button (click)="deleteemail()" >Ok</button>
  </div>
</ng-container>
<ng-container *ngIf="screenType=='deletesource'">
  <h1 mat-dialog-title style="text-align: center;">Conformation</h1>
<div mat-dialog-content>
  <h4> Do you want to delete ....?</h4>
</div>
    <div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button (click)="deletesource()" >Ok</button>
  </div>
</ng-container>

<ng-container *ngIf="screenType=='logout'">
  <h1 mat-dialog-title style="text-align: center;">Conformation</h1>
<div mat-dialog-content>
  <h4> Do you want to Logout ....?</h4>
</div>
    <div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button (click)="logout()" >Ok</button>
  </div>
</ng-container>

<ng-container *ngIf="screenType=='deletecampaigns'">
  <h1 mat-dialog-title style="text-align: center;">Conformation</h1>
<div mat-dialog-content>
  <h4> Do you want to delete campaign ....?</h4>
</div>
    <div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button (click)="deletecampaign()" >Ok</button>
  </div>
</ng-container>