import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class MyInterceptorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private jwtHelperService: JwtHelperService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('access_token');
    let tokenizedRequest;

    if (token && this.jwtHelperService.isTokenExpired(token)) {
      tokenizedRequest = request;
      this.router.navigate(['/login']); 
    } else {
      tokenizedRequest = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token)
      });
    }
    return next.handle(tokenizedRequest);
  }
}

