import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiServiceService } from 'src/app/service/api-service.service';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements OnInit {
screenType:any


  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private loginservice: ApiServiceService,
    public snackBar: MatSnackBar,
    private router: Router,
  ) { }


  ngOnInit(): void {
    this.screenType=this.data.ScreenType
    console.log(this.data)

  }



deleteUtm(){
  this.loginservice.RemoiveUTMBuilders(this.data.data.id).subscribe((result)=>{
    if(result){
      this.snackBar.open('Deleted', '', {duration: 3000 });
      this.dialogRef.close(true)
    }
  },(error)=>{
    if(error.status==200){
      this.snackBar.open('Deleted', '', {duration: 3000 });
      this.dialogRef.close(true)
    }
  })
}
deleteemail(){
  this.loginservice.deleteemail(this.data.data.id).subscribe(result=>{
    if(result){
      this.snackBar.open('Deleted', '', {duration: 3000 });
      this.dialogRef.close(true)    }
  },(error)=>{
    if(error.status==200){
      this.snackBar.open('Deleted', '', {duration: 3000 });
      this.dialogRef.close(true)
    }
  })}


  deletesource(){
    this.loginservice.DeleteSource(this.data.data.id).subscribe(result=>{
      if(result){
        this.snackBar.open('Deleted', '', {duration: 3000 });
        this.dialogRef.close(true)    }
    },(error)=>{
      if(error.status==200){
        this.snackBar.open('Deleted', '', {duration: 3000 });
        this.dialogRef.close(true)
      }
    })}

  logout() {
    this.dialogRef.close(true)
    localStorage.removeItem("access_token");
    this.router.navigate(['/login'])

  }
onNoClick(){
  this.dialogRef.close(true)
}

deletecampaign(){
  
}

}
