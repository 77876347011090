import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiServiceService } from 'src/app/service/api-service.service';

@Component({
  selector: 'app-create-are-choose-tage',
  templateUrl: './create-are-choose-tage.component.html',
  styleUrls: ['./create-are-choose-tage.component.scss']
})
export class CreateAreChooseTageComponent implements OnInit {
  Form: FormGroup;
  GetAllnamesList=[]
  GetAllvaluesList=[]
  ScreenType:any;
  constructor(
    private formBuilder: FormBuilder,
    private matDialogRef: MatDialogRef<CreateAreChooseTageComponent>,
    private loginservice: ApiServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.Form = this.formBuilder.group({
      name: ['',[Validators.required]],
      value: ['',[Validators.required]],
      pageCode: ['',[Validators.required]],
      score: ['',[Validators.required]],
    });
   }

  ngOnInit(): void {
    console.log(this.data)
    this.ScreenType=this.data.screenType
    if(this.data){
      this.GetAllTagColumns()
    }
  }
  GetUniqueTagValues(data){
    this.loginservice.GetUniqueTagValues(this.data.SourceId,data.id,data.fieldName).subscribe((result:any)=>{
      if(result){
        this.GetAllvaluesList=result
      }
    })
  }

  GetAllTagColumns(){
this.loginservice.GetAllTagColumns(this.data.SourceId).subscribe((result:any)=>{
  if(result){
    this.GetAllnamesList=result
  }
})
  }


  onSubmit(){
this.matDialogRef.close(this.Form.value)
  }

  onSubmit2(){
    this.matDialogRef.close(this.Form.value)
      }

}
