<div class="d-flex flex-row align-items-center modal-header">
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <span><b>Choose </b></span> <br>
        <span>Action (or)  Condition</span>
    </h2>
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>
  
  
    <div class="container">
      <div class="row justify-content-start">
          <div class="col-12 p-2">
              <button class="fw-600" mat-menu-item (click)="radioChange('CreateAction')">
                <!-- <mat-icon style="color:rgb(255,193,111) ;">speaker_notes</mat-icon> -->
                Add a Action
              </button>
              <button class="fw-600" mat-menu-item (click)="radioChange('CreateContiction')">
                <!-- <mat-icon style="color:rgb(66,135,245) ;">insert_drive_file</mat-icon> -->
                Add a Condition
              </button>
          </div>
      </div>
  </div>
