<ng-container *ngIf="Screentype=='CreateProject'">
    <div class="d-flex flex-row align-items-center modal-header">
        <mat-icon mat-dialog-close>keyboard_backspace</mat-icon>
        <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
            <span>Create Project</span>
        </h2>
    </div>
    <mat-dialog-content class="mr-0 ml-0">
        <form [formGroup]="Form1">
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>Project Name</mat-label>
                <input matInput formControlName="projectName" placeholder="Project">
            </mat-form-field>
        </div>
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>BaseUrl</mat-label>
                <input matInput formControlName="baseUrl" placeholder="Project">
            </mat-form-field>
        </div>
        </form>
    </mat-dialog-content>

    <mat-dialog-actions align="end" class="my-1">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-raised-button class="b-blue" [disabled]="!Form1.valid" (click)="onSubmit()">Apply</button>
    </mat-dialog-actions>

</ng-container>


<ng-container *ngIf="Screentype=='CreateLeadscore'">
    <div class="d-flex flex-row align-items-center modal-header">
        <mat-icon mat-dialog-close>keyboard_backspace</mat-icon>
        <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
            <span>Create LeadScore</span>
        </h2>
    </div>
    <mat-dialog-content class="mr-0 ml-0">
        <form [formGroup]="Form">
            <div class="row">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Config Name</mat-label>
                    <input matInput formControlName="ConfigName" placeholder="Config Name">
                </mat-form-field>
            </div>
            <!-- Postive Score  -->
            <h2>Postive Score</h2>
            <div class="row">
                <div class="lg-col-6" >
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Open Score</mat-label>
                        <input matInput formControlName="OpenScore" type="number">
                    </mat-form-field>
                </div>
                <div class="col-6" >
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Click Score</mat-label>
                        <input matInput formControlName="ClickScore" type="number">
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>FormSubmission Score</mat-label>
                        <input matInput formControlName="FormSubmissionScore" type="number">
                    </mat-form-field>
                </div>
            </div>

            <!-- Negative Score  -->
            <h2>Negative Score</h2>
            <div class="row">
                <div class="col-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Unsubscribe Score</mat-label>
                        <input matInput formControlName="UnsubscribeScore" type="number">
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Bounces Score</mat-label>
                        <input matInput formControlName="BouncesScore" type="number">
                    </mat-form-field>
                </div>
            </div>
            <!-- Key_PageVisitCredits  -->
            <div class="row" style="background-color: rgb(202, 198, 198);color: black;padding: 10px 4px 2px;">
                <div class="col-10">
                    Key Page Visit Credits
                </div>
                <div class="col-2">
                    <mat-icon style="color: black !important;" (click)="CreateAreChooseTage()">add_box</mat-icon>
                </div>
            </div>
            <div formArrayName="Key_PageVisitCredits">
                <div class="row" *ngFor="let file of AddTags().controls; let i=index" [formGroupName]="i">
                    <div class="row">
                        <div class="col-5">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>page Code</mat-label>
                                <mat-select formControlName="pageCode">
                                    <mat-option *ngFor="let x of GetAllpageCode" [value]="x.pageCode">
                                        {{x.pagetName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-5">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>score</mat-label>
                                <input matInput formControlName="score" type="number">
                            </mat-form-field>
                        </div>
                        <div class="col-2">
                            <mat-icon style="margin-top: 10px;color:black;"
                                (click)="Removecondictuon(i)">delete</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </mat-dialog-content>

    <mat-dialog-actions align="end" class="my-1">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-raised-button class="b-blue" (click)="onSubmit1()">Apply</button>
    </mat-dialog-actions>

</ng-container>