import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
const baseUrl = 'https://swagger.leadgourmet.com'


@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  constructor(
    private http: HttpClient,
  ) { }

  login(reqData: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(baseUrl + '/api/Auth/PTlogin', reqData, httpOptions)
  }

  GetAllUsersList() {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(baseUrl + '/api/User/FindAllUsers', httpOptions)
  }
  GetAllTagColumns(SourceId) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(baseUrl + '/api/Email/GetAllTagColumns?SourceId='+SourceId, httpOptions)
  }

  GetUniqueTagValues(sourceId,Id,FieldName) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(baseUrl + '/api/Email/GetUniqueTagValues?sourceId='+sourceId+'&Id='+Id+'&FieldName='+FieldName, httpOptions)
  }


  getAllmailesList(size:number,pagenumber:number,SourceId:string) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(baseUrl + '/api/Email/GetAllEmails?noofRecord='+size+'&pageno='+pagenumber+'&SourceId='+SourceId, httpOptions)
  }

  GetAllSources(pagesize,pageno) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(baseUrl + '/api/Source/GetAllSources?pagesize='+pagesize+'&pageno='+pageno, httpOptions)
  }
  GetSMTPProviderDD() {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(baseUrl + '/api/SMTP/GetSMTPProviderDD', httpOptions)
  }


  

  GetAllDDSource(){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(baseUrl + '/api/Source/GetAllDDSource', httpOptions)
  }
  

  GetAllLeadsList(size:number,pagenumber:number) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(baseUrl + '/api/Lead/GetAllLeads?noofRecord='+size+'&pageno='+pagenumber, httpOptions)
  }
  CreateUTMBuilder(reqData: any) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(baseUrl + '/api/UTM/CreateUTM', reqData, httpOptions)
  }
// 000000000 
  CreateEmail(data:any) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(baseUrl + '/api/Email/CreateEmail', data, httpOptions)
  }
  CreateSource(data:any) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }) }
    return this.http.post(baseUrl + '/api/Source/CreateSource', data, httpOptions)
  }

  UploadImportFile(formData:any,SorceId) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }) };
    return this.http.post(baseUrl + '/api/Email/UploadImportFile?SorceId='+SorceId, formData, { responseType: 'text' })
  }


  DownloadImportTemplate(sourceId) {
    return this.http.get(baseUrl + '/api/Email/DownloadImportTemplate1?sourceId='+sourceId,
    {observe:'response', responseType: 'blob'});
  }

  CreateLead(LinkId:any,Emails:any) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(baseUrl + '/api/Email/CreateEmail?LinkId='+LinkId+'&Emails='+Emails, {}, httpOptions)
  }


  GetAllUTMBuilders(size:number,pagenumber:number) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(baseUrl + '/api/UTM/GetAllUTMBuilders?noofrecords='+size+'&pageno='+pagenumber, httpOptions)
  }
  GetUTMBuilderById(id:number){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(baseUrl + '/api/UTM/GetUTMBuilderById?id='+id, httpOptions)
  }

  RemoiveUTMBuilders(id:number) {
    return this.http.delete(baseUrl + '/api/UTM/DeleteUTMBuilder?id='+id)
  }
  deleteemail(id:number) {
    return this.http.delete(baseUrl + '/api/Email/DeleteEmail?id='+id)
  }
  DeleteSource(id:number) {
    return this.http.delete(baseUrl + '/api/Source/DeleteSource?id='+id)
  }
  FindAllUsers() {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(baseUrl + '/api/User/FindAllUsers', httpOptions)
  }
  GetAllLinksDD() {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(baseUrl + '/api/Email/GetAllLinksDD', httpOptions)
  }
  GetloginUserDetailsById() {
    const userid=localStorage.getItem("UserId");
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(baseUrl + '/api/User/PTFindUserById?id='+userid, httpOptions)
  }

  Createuser(formData:any) {
    const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    return this.http.post(baseUrl + '/api/User/CreateUser', formData, { headers: headers })
  }


  GetGoogleAnalyticsv(id:number) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(baseUrl + '/api/UTM/GetGoogleAnalyticsv4?linkId='+id, httpOptions)
  }

  GetCampaignRecipList(campaignId,date:any,statusType) {
    console.log(statusType)
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(baseUrl + '/api/Statistics/GetCampaignRecipList?date='+date+'&CampaignId='+campaignId+'&statusType='+statusType, httpOptions)
  }

  GetCampaignStatschart(data) {
    const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    return this.http.post(baseUrl + '/api/Statistics/GetCampaignStats',data, { headers: headers })
  }

  MatomoAnlytics(startDate:any,endDate:any,CampaignID:any,IsPagewise:any) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(baseUrl + '/api/UTM/MatomoAnlytics?startDate='+startDate+'&endDate='+endDate+'&CampaignID='+CampaignID+'&IsPagewise='+IsPagewise, httpOptions)
  }




  linkId(code:number) {
    return this.http.get(baseUrl + '/api/UTM/Short?code='+code)
  }

  GetAllTagsOfSource(SourceId) {
    return this.http.get(baseUrl + '/api/Source/GetAllTagsOfSource?SourceId='+SourceId)
  }


  

  CreateCampaign(data:any) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(baseUrl + '/api/Campaign/CreateCampaign', data, httpOptions)
  }
  CreateLeadScoringCongfig(data:any) {
    const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    return this.http.post(baseUrl + '/api/Lead/CreateLeadScoringCongfig', data, { headers: headers })
  }

  GetAllCampaigns(PageCount,pageNo,projectId,campaignType,StartDate,EndDate) {
    return this.http.get(baseUrl + '/api/Campaign/GetAllCampaigns?pageNo='+pageNo+'&PageCount='+PageCount+'&projectId='+projectId+'&campaignType='+campaignType+'&StartDate='+StartDate+'&EndDate='+EndDate)
  }

  GetVisitordtlsBasedonCookies(projectId,date,cookiesType) {
    return this.http.get(baseUrl + '/api/Statistics/GetVisitordtlsBasedonCookies?projectId='+projectId+'&date='+date+'&cookiesType='+cookiesType)
  }

  
  GetAllCampaignsDD(projectId) {
    return this.http.get(baseUrl + '/api/Campaign/GetAllCampaignsDD?projectId='+projectId)
  }

  AllProjects() {
    return this.http.get(baseUrl + '/api/UTM/GetAllProjectsDD')
  }


  CrateProject(result) {
    return this.http.post(baseUrl + '/api/UTM/CrateProject',result)
  }

  getconditioncolumns(){
    return this.http.get(baseUrl + '/api/Lead/GetTableColumnsforLSConfig')
  }

  getLeadscore(ProjectId) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get(baseUrl + '/api/Lead/GetLeadScoringConfigDD?ProjectId='+ProjectId,httpOptions)
  }


  CreateLeadscore(data) {
    const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    return this.http.post(baseUrl + '/api/Lead/CreateLeadScoreConfig',data, { headers: headers })
  }
 

  GetCampaignStats(CampaignId) {
    return this.http.get(baseUrl + '/api/Campaign/GetCampaignStats?CampaignId='+CampaignId)
  }
  GetLeadScoringCongfigDD() {
    return this.http.get(baseUrl + '/api/Lead/GetAllLeadScoringCongfig')
  }
  GetLeadScoringCongfig() {
    return this.http.get(baseUrl + '/api/Lead/GetListofScoringCongfig')
  }
  GetPageTitles(id) {
    return this.http.get(baseUrl + '/api/Statistics/GetPageTitles?projectId='+id)
  }
  GetAllTempsForDD(id) {
    return this.http.get(baseUrl + '/api/MediaLib/GetAllTempsForDD?projectId='+id)
  }
  GetAllTemplates(ProjectId,Pageno,PageSize) {
    return this.http.get(baseUrl + '/api/MediaLib/GetAllTemplates?ProjectId='+ProjectId+'&Pageno='+Pageno+'&PageSize='+PageSize)
  }


  GetVisitorsList(date:any,campaignId:any,pagesize:any,pageNumber:any) {
    return this.http.get(baseUrl + '/api/UTM/GetVisitorsList?startDate='+date+'&endDate='+date+'&CampaignId='+campaignId+'&pagesize='+pagesize+'&pageNumber='+pageNumber)
  }
  
  GetHtmlTemplateContent(TemplateId) {
    return this.http.get(baseUrl + '/api/MediaLib/GetHtmlTemplateContent?TemplateId='+TemplateId)
  }

  GetAlloverView(startDate,endDate,projectId,Pagecode,isOverView,pagesize,pageNumber) {
    return this.http.get(baseUrl + '/api/Statistics/GetVisitorsListforOverView?startDate='+startDate+'&endDate='+endDate+'&projectId='+projectId+'&isOverView='+isOverView+'&Pagecode='+Pagecode+'&pageNumber='+pageNumber+'&pagesize='+pagesize)
  }

  GetStatsBasedonCookies(startDate,endDate,projectId,) {
    return this.http.get(baseUrl + '/api/Statistics/GetStatsBasedonCookies?startDate='+startDate+'&endDate='+endDate+'&projectId='+projectId)
  }


  GetVisitorsListforcampaign(startDate,endDate,CampaignId,ProjectId,isOverView) {
    return this.http.get(baseUrl + '/api/Statistics/GetVisitorsListforcampaign?startDate='+startDate+'&endDate='+endDate+'&CampaignId='+CampaignId+'&isOverView='+isOverView+'&ProjectId='+ProjectId)
  }
  GetVisitDetailsForOverView(startDate,endDate,projectId,pageCode){
    return this.http.get(baseUrl + '/api/Statistics/GetVisitDetailsForOverView?startDate='+startDate+'&endDate='+endDate+'&projectId='+projectId+'&pageCode='+pageCode)

  }
  CreateemailModel(data){
    const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    return this.http.post(baseUrl + '/api/Campaign/CreateModel', data, { headers: headers })
  }
  CalculateLeadscore(data){
    const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    return this.http.post(baseUrl + '/api/Lead/CalculateLeadscore', data, { headers: headers })
  }


  RootCampaign(data){
    const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    return this.http.patch(baseUrl + '/api/Campaign/RootCampaign', data, { headers: headers })
  }

  RootCampaignWithTestEmails(data){
    const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
    return this.http.patch(baseUrl + '/api/Campaign/RootCampaignWithTestEmails', data, { headers: headers })
  }

  
  GetCustomerJournyvoerviwe(RecipId,projectId,Contact){
  return this.http.get(baseUrl + '/api/Statistics/GetCustomerJourny?RecipId='+RecipId+'&projectId='+projectId,+'&Contact='+Contact)
  }

  GetCustomerJournycomp(RecipId,campaignId,Contact){
    return this.http.get(baseUrl + '/api/Statistics/GetCustomerJourny?RecipId='+RecipId+'&campaignId='+campaignId,+'&Contact='+Contact)
  
    }


    getHtmlFile(url: string): Observable<string> {
      return this.http.get(url, { responseType: 'text' });
    }


    CreateAction(reqData: any): Observable<any> {
      const headers = new HttpHeaders().append('Content-Disposition', 'multipart/form-data');
      return this.http.post(baseUrl + '/api/Automation/CreateAction', reqData, { headers: headers })
    }


}
