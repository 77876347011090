<div class="d-flex flex-row align-items-center modal-header">
    <mat-icon mat-dialog-close>keyboard_backspace</mat-icon>
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <span> Create Model (2/2)</span>
    </h2>
</div>
<mat-dialog-content class="mr-0 ml-0">
    <form [formGroup]="Form">
        <div class="row ">
            <div class="col-6">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label><mat-icon>list</mat-icon> Select a Source</mat-label>
                    <mat-select formControlName="SourceId">
                        <mat-option *ngFor="let x of GetAllSourcesList" [value]="x.id" (click)="GetSourceCount(x)">
                            {{x.sourceName |titlecase}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Count</mat-label>
                    <input matInput [(ngModel)]="SourceCount" [ngModelOptions]="{standalone: true}">
                </mat-form-field>
            </div>
            <div class="col-3 p-0">
                <mat-icon style="cursor: pointer;margin-top: 15px;" (click)="ListOfEmails()">visibility</mat-icon>
            </div>
        </div>
        <div class="row ">
            <div class="col-12">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label><mat-icon>person</mat-icon> Sender</mat-label>
                    <input matInput formControlName="Sender">
                </mat-form-field>
            </div>
        </div>
        <div class="row ">
            <div class="col-12">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label><mat-icon>subject</mat-icon> Subject</mat-label>
                    <input matInput formControlName="Subject">
                </mat-form-field>
            </div>
        </div>
        <div class="row ">
            <div class="col-12">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label><mat-icon>mail</mat-icon> Sender Email</mat-label>
                    <input matInput formControlName="EmailGroupOrDomain">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label><mat-icon>send</mat-icon> Select a Provider</mat-label>
                    <mat-select formControlName="SMTPConfigId">
                        <mat-option *ngFor="let x of GetSMTPProviderDDList" [value]="x.smtpConfigId">
                            {{x.smtpProviderName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <!-- Lead score  -->
<div class="row">
    <mat-slide-toggle formControlName="Leadscorestatus" [(ngModel)]="Leadscorestatus" ><b>Lead score </b></mat-slide-toggle>
    <ng-container *ngIf="Leadscorestatus">
        <div class="row ">
            <div class="col-10">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label><mat-icon>folder</mat-icon><b>Lead score</b></mat-label>
                <mat-select formControlName="Leadscoreid">
                    <mat-option *ngFor="let x of AllLeadscore"
                        [value]="x.id">
                        <b>{{x.name}}</b>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            </div>
            <div class="col-2"> 
                <mat-icon style="margin-top: 10px;" (click)="CreateLeadscore()">add_box</mat-icon>
            </div>
        </div>
    </ng-container>
</div>
<!-- Root immediately  -->
        <div class="row">
            <div class="col-12">
                <mat-radio-group formControlName="RootImmediate" [(ngModel)]="RootImmediate">
                    <mat-radio-button value="1" class="p-2"><mat-icon
                            class="iconpostion">alarm_on</mat-icon><span><b>Root immediately
                            </b></span></mat-radio-button>
                    <mat-radio-button value="2" class="p-2"><mat-icon
                            class="iconpostion">calendar_today</mat-icon><span><b>Schedule
                                campaign</b></span></mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div class="row" *ngIf="RootImmediate=='2'">
            <div class="col-6">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label><mat-icon>calendar_today</mat-icon> Launch Date</mat-label>
                    <input matInput [matDatepicker]="startdatepicker1" style="color: black;"
                        formControlName="LaunchDate">
                    <mat-datepicker-toggle matSuffix [for]="startdatepicker1"></mat-datepicker-toggle>
                    <mat-datepicker #startdatepicker1></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field appearance="outline">
                    <mat-label><mat-icon>calendar_today</mat-icon>LaunchTime (HH:MM:SS)</mat-label>
                    <input matInput type="text" formControlName="LaunchTime">
                </mat-form-field>
            </div>
        </div>
        <!-- Choose a template   -->
        <div class="row">
            <div class="col-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label><mat-icon>date_range</mat-icon> Choose a template *</mat-label>
                    <mat-select formControlName="SelectMediaType" [(ngModel)]="SeletedMedia">
                        <mat-option [value]="1" (click)="FromLocal()"><img class="drowdownimage"
                                src="../../../assets/svg-icons/gui-folder-open-svgrepo-com.svg">
                            <b>Form Local</b></mat-option>
                        <mat-option [value]="2" (click)="FromMediaLibrary()"><img class="drowdownimage"
                                src="../../../assets/svg-icons/media-color-1-image-01-svgrepo-com.svg">
                            <b>From Media Library</b></mat-option>
                        <mat-option [value]="3"><img class="drowdownimage"
                                src="../../../assets/svg-icons/google-drive-svgrepo-com.svg">
                            <b>Google Drive</b></mat-option>
                        <mat-option [value]="4"><img class="drowdownimage"
                                src="../../../assets/svg-icons/ms-onedrive-svgrepo-com.svg">
                            <b>Ondrive </b></mat-option>
                        <mat-option [value]="5"><img class="drowdownimage"
                                src="../../../assets/svg-icons/dropbox-color-svgrepo-com.svg">
                            <b>Dropbox</b></mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6">
                <ng-container>
                    <div class="row" style="background-color: rgb(202, 198, 198);color: black;padding: 10px 4px 2px;">
                        <div class="col-10">
                            Filter Contact
                        </div>
                        <div class="col-2">
                            <mat-icon style="color: black !important;"
                                (click)="CreateAreChooseTage()">add_box</mat-icon>
                        </div>
                    </div>
                    <div class="row">
                        <mat-radio-group formControlName="IncludeOrExclude" [(ngModel)]="IncludeOrExclude">
                            <mat-radio-button value="1" class="p-2"><b>Include</b></mat-radio-button>
                            <mat-radio-button value="2" class="p-2"><b>Exclude</b></mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div formArrayName="AddTags">
                        <div class="row" *ngFor="let file of AddTags().controls; let i=index" [formGroupName]="i">
                            <div class="row">
                                <div class="col-5">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Name <span class="danger"></span></mat-label>
                                        <input matInput type="text" readonly="true" formControlName="fieldName">
                                    </mat-form-field>
                                </div>
                                <div class="col-5">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Value<span class="danger"></span></mat-label>
                                        <input matInput readonly="true" type="text" formControlName="fieldValue">
                                    </mat-form-field>
                                </div>
                                <div class="col-2">
                                    <mat-icon style="margin-top: 10px;color:black;"
                                        (click)="Removecondictuon(i)">delete</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

        </div>

        <!-- Form Local  -->
        <ng-container *ngIf="SeletedMedia==1">
            <div class="row">
                <div class="col-10">
                    <input type="file" (change)="onSelectforfile($event)">
                </div>
                <div class="col-2">
                    <mat-icon *ngIf="showfileview" (click)="showfileviewhtml()"
                        class="icon-options">visibility</mat-icon>
                </div>
            </div>
        </ng-container>

        <!-- From Media Library  -->

        <ng-container *ngIf="SeletedMedia==2&&seletedfileName!=null">
            <div class="row">
                <div class="col-10">
                    <p>{{seletedfileName}}</p>
                </div>
                <div class="col-2">
                    <mat-icon  (click)="showfileviewhtml()"
                        class="icon-options">visibility</mat-icon>
                </div>
            </div>
        </ng-container>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="my-1">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button class="b-blue" [disabled]="!Form.valid" (click)="onSubmit()">Next</button>
</mat-dialog-actions>