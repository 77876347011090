import { Component, OnInit,Inject } from '@angular/core';
import { inject } from '@angular/core/testing';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiServiceService } from 'src/app/service/api-service.service';
@Component({
  selector: 'app-create-leads',
  templateUrl: './create-leads.component.html',
  styleUrls: ['./create-leads.component.css']
})
export class CreateLeadsComponent {
  Form: FormGroup;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private loginservice: ApiServiceService,
    private matDialogRef: MatDialogRef<CreateLeadsComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,


  ) {
    this.Form = this.formBuilder.group({
   
    });
  }
  ngOnInit(): void {
 
  }





  onSubmit() {
    // const data = this.Form.value
    // this.loginservice.CreateUTMBuilder(data).subscribe((result: any) => {
    //   if (result) {
    //     this.snackBar.open('UTM Builder createrd', '', {duration: 3000 });
    //     this.matDialogRef.close(true)
    //   }
    // })
  }

}
