<div class="d-flex flex-row align-items-center modal-header">
    <mat-icon mat-dialog-close>keyboard_backspace</mat-icon>
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <span>Template Customization </span>
    </h2>
</div>
<mat-dialog-content class="mr-0 ml-0" id="myComponent" #dialogContent>
    <div class="row"  >
        <div class="col-8">
            <h2 > <span class="titleofchart">Template Design</span></h2>
            <div (click)="onClick()">
                <div  #htmlContainer [innerHTML]="htmlContent">
                </div>
            </div >
        </div>
        <div class="col-4" style="border-left:1px solid black;">
            <h2><span class="titleofchart">Selected Columns</span> </h2>
            <form [formGroup]="Form">
                <div formArrayName="AddTags">
                <div class="row"
                    *ngFor="let file of AddTags().controls; let i=index" [formGroupName]="i">
                    <div class="row">
                                <div class="col-5">
                                    <mat-form-field appearance="legacy" class="w-100">
                                        <mat-label>selected Text <span class="danger"></span></mat-label>
                                        <input matInput type="text" readonly="true" formControlName="selectedText">
                                    </mat-form-field>
                                </div>
                                <div class="col-5">
                                    <mat-form-field  class="w-100">
                                        <mat-label>Column Name</mat-label>
                                        <mat-select formControlName="tagColumnId" >
                                            <mat-option *ngFor="let x of GetAllnamesList" [value]="x" (click)="GetUniqueTagValues(x.id)">
                                                {{x.fieldName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-2">
                                    <mat-icon style="margin-top: 10px;color:black;" (click)="Removecondictuon(i)">delete</mat-icon>
                                </div>
                    </div>
                </div>
            </div>
            </form>
            <div class="row align-content-end" *ngIf="AddTags().controls.length!=0">
                <div class="col-4"><button mat-button mat-dialog-close>Cancel</button></div>
                <div class="col-4"><button mat-raised-button class="b-blue" (click)="onSubmit()">Next</button></div>
            </div>
        </div>
    </div>
</mat-dialog-content>