<!-- CreateTage  -->
<ng-container *ngIf="ScreenType=='CreateTage'">
    <div class="d-flex flex-row align-items-center modal-header">
        <mat-icon mat-dialog-close>keyboard_backspace</mat-icon>
        <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
            <span></span>
        </h2>
    </div>
    <mat-dialog-content class="mr-0 ml-0">
        <form [formGroup]="Form">
            <div class="row">
                <div class="col-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Tag Name</mat-label>
                        <mat-select formControlName="name">
                            <mat-option *ngFor="let x of GetAllnamesList" [value]="x" (click)="GetUniqueTagValues(x)">
                                {{x.fieldName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Value</mat-label>
                        <mat-select formControlName="value">
                            <mat-option *ngFor="let x of GetAllvaluesList" [value]="x" >
                                {{x}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </mat-dialog-content>
    
    <mat-dialog-actions align="end" class="my-1">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-raised-button class="b-blue"  (click)="onSubmit()">Apply</button>
    </mat-dialog-actions>
</ng-container>
