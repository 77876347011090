import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceService } from '../service/api-service.service';

@Component({
  selector: 'app-reload',
  templateUrl: './reload.component.html',
  styleUrls: ['./reload.component.css']
})
export class ReloadComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginservice: ApiServiceService
    ) {}
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      console.log(params)
      if(params?.['id']) {
        this.loginservice.linkId(params['id']).subscribe((result:any)=>{
            console.log(result)
            window.location.href = result
        },(error)=>{
          if(error.status==200){
            window.location.href = error.error.text
          }
        })
      } 
    });

}
  }

  


