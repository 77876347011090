
<div class="row align-items-center modal-header">
    <div class="col-2">
        <mat-icon mat-dialog-close>keyboard_backspace</mat-icon></div>
    <div class="col-8"><h2 mat-dialog-title
            class="m-0 dialog-title text-center w-100">
            <span> Create Contact</span>
        </h2></div>
    <div class="col-2">
        <mat-icon style="color:black" [matMenuTriggerFor]="beforeMenu">more_vert</mat-icon>
        <mat-menu #beforeMenu="matMenu" xPosition="before">
            <button class="fw-600" mat-menu-item (click)="fileInput1.click()">
                <input #fileInput1 accept=".csv,.xlsx,.xls" type="file"
                    (change)="handleFileInput($event)" style="display:none;" />
                <mat-icon>import_export</mat-icon> Import Data
            </button>
            <button class="fw-600" mat-menu-item (click)="downloadTemplate()">
                <mat-icon>download</mat-icon> Download Template
            </button>
        </mat-menu>
    </div>
</div>
<mat-dialog-content class="mr-0 ml-0">
    <form [formGroup]="Form">
        <div class="row mt-2">
            <mat-form-field appearance="outline">
                <mat-label>Source Name</mat-label>
                <input matInput placeholder type="text"
                    formControlName="sourcename">
            </mat-form-field>
        </div>
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput placeholder type="email" formControlName="email">
            </mat-form-field>
        </div>
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>first Name</mat-label>
                <input matInput placeholder type="text"
                    formControlName="firstName">
            </mat-form-field>
        </div>
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>last Name</mat-label>
                <input matInput placeholder type="text"
                    formControlName="lastName">
            </mat-form-field>
        </div>
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>phone Number</mat-label>
                <input matInput placeholder type="number"
                    formControlName="phoneNumber">
            </mat-form-field>
        </div>
        <div class="row" style="background-color: rgb(202, 198, 198);color: black;padding: 10px 4px 2px;">
            <div class="col-10">
                Add Tag
            </div>
            <div class="col-2">
                <mat-icon style="color: black !important;" (click)="Addcondictuon()">add_box</mat-icon>
            </div>
        </div>
        <div formArrayName="AddTags">
            <div class="row"
                *ngFor="let file of AddTags().controls; let i=index"
                [formGroupName]="i">
                <div class="row">
                    <div class="col-5">
                        <mat-form-field appearance="legacy" class="w-100">
                            <mat-label>Name <span class="danger"></span></mat-label>
                            <input matInput type="text" formControlName="fieldName">

                        </mat-form-field>
                    </div>
                    <div class="col-5">
                        <mat-form-field appearance="legacy" class="w-100">
                            <mat-label>Value<span class="danger"></span></mat-label>
                            <input matInput type="text" formControlName="fieldValue">

                        </mat-form-field>
                    </div>
                    <div class="col-2">
                        <mat-icon style="margin-top: 10px;color:black;" (click)="Removecondictuon(i)">delete</mat-icon>
                    </div>
                </div>
            </div>
        </div> 
    </form> 
</mat-dialog-content>
<mat-dialog-actions align="end" class="my-1">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button class="b-black" [disabled]="!Form.valid"
        (click)="onSubmit()">Apply</button>
</mat-dialog-actions>