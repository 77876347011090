<div class="d-flex flex-row align-items-center modal-header">
    <mat-icon mat-dialog-close>keyboard_backspace</mat-icon>
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <span>  User Profile</span>
    </h2>
</div>
<mat-dialog-content class="mr-0 ml-0">
    <form [formGroup]="userForm"> 
            <div class="row  justify-content-center">
                <div class="col-1 p-0 mb-1">
                    <div class="avatar">
                        <img class="imgclass" [src]="profilePick ? profilePick:'assets/avatar.svg'">
                    </div>
                </div>
                <div class="col-1 mt-5 mr-5">
                    <div
                        style="background-color: rgb(232, 239, 36);width: 30px; cursor: pointer;height: 30px;border-radius: 30px;position: relative; left:6px;">
                        <mat-icon style="position: relative;left: 5px;top: 5px;font-size: 20px; cursor: pointer;">
                            edit
                        </mat-icon>
                        <input (change)="onFileChange($event)" style="position: relative; cursor: pointer;
                                top: -24px; width: 30px; height: 40px; opacity: -0.5; " id="file-input" type="file" />
                    </div>
                </div>
            </div>
            <div class="row firstAndLastName">
                <div class="col-6">
                    <mat-form-field class="w-100">
                        <input matInput type="text" placeholder="First Name " formControlName="FirstName">
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field class="w-100">
                        <input matInput type="text" placeholder="Last Name " formControlName="LastName">
                    </mat-form-field>
                </div>
            </div>
    
            <div class="row firstAndLastName">
                <div class="col-6">
                    <mat-form-field class="w-100">
                        <mat-label>Gender</mat-label>
                        <mat-select formControlName="Gender">
                            <mat-option value="Male">Male</mat-option>
                            <mat-option value="Female">Female</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field class="w-100">
                        <input matInput type="text" placeholder="Position" formControlName="Position">
                    </mat-form-field>
                </div>
            </div>
    
    
            <mat-form-field class="w-100">
                <input matInput type="number" placeholder="Phone Number" formControlName="PhoneNumber">
            </mat-form-field>
    
            <mat-form-field class="w-100">
                <input matInput type="email" placeholder="Email " formControlName="Email">
            </mat-form-field>
            <mat-form-field class="w-100">
                <input matInput type="text" placeholder="Address " formControlName="Address">
            </mat-form-field>
            <div class="row countryCityZipCode">
                <div class="col-4">
                    <mat-form-field class="w-100">
                        <input matInput type="text" placeholder="Country " formControlName="Country">
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field class="w-100">
                        <input matInput type="text" placeholder="City " formControlName="City">
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field class="w-100">
                        <input matInput type="text" placeholder="Zipcode " formControlName="ZipCode">
                    </mat-form-field>
    
                </div>
            </div>    
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="my-1">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button class="b-blue" [disabled]="!userForm.valid" (click)="onSubmit()">Apply</button>
</mat-dialog-actions>