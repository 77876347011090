import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreateUtmComponent } from './components/create-utm/create-utm.component';
import { HeaderComponent } from './comm/header/header.component';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './share/share/share.module';
import { MatButtonModule } from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { CreateEmailComponent } from './components/create-email/create-email.component';
import { CreateLeadsComponent } from './components/create-leads/create-leads.component';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ReloadComponent } from './reload/reload.component';
import { CreateCampaignsComponent } from './components/create-campaigns/create-campaigns.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CreateSourceComponent } from './components/create-source/create-source.component';
import { LoginComponent } from './comm/login/login.component';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { AgmJsMarkerClustererModule, ClusterManager } from '@agm/js-marker-clusterer';
import { MapDetailsComponent } from './components/map-details/map-details.component';
import { CreateProjectComponent } from './components/create-project/create-project.component';
import { CreateMailModelCampaignComponent } from './components/create-mail-model-campaign/create-mail-model-campaign.component';
import { MyInterceptorInterceptor } from './my-interceptor.interceptor';
import { CreateAreChooseTageComponent } from './components/create-are-choose-tage/create-are-choose-tage.component';
import { CommanForAllComponent } from './components/comman-for-all/comman-for-all.component';
import { ShowTemplateViewComponent } from './components/show-template-view/show-template-view.component';
import { LeadAutomationComponent } from './pages/leads/lead-automation/lead-automation.component';
import { NodeComponent } from './pages/leads/lead-automation/components/node/node.component';
import { InputSocketComponent } from './pages/leads/lead-automation/components/socket/input-socket.component';
import { OutputSocketComponent } from './pages/leads/lead-automation/components/socket/output-socket.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AutomationActionsListComponent } from './pages/leads/lead-automation/components/automation-actions-list/automation-actions-list.component';
import { CreateActionComponent } from './pages/leads/lead-automation/components/create-action/create-action.component';
import { LeadScoreComponent } from './pages/leads/lead-automation/Lead-score/lead-score/lead-score.component';
import { LeadScoreConfigurationComponent } from './components/lead-score-configuration/lead-score-configuration.component';
import { ChooseActionsConditionsComponent } from './pages/leads/lead-automation/components/choose-actions-conditions/choose-actions-conditions.component';
export function tokenGetter() {
  return localStorage.getItem("access_token");
}
@NgModule({
  declarations: [
    AppComponent,
    CreateUtmComponent,
    LoginComponent,
    HeaderComponent,
    DeleteDialogComponent,
    CreateEmailComponent,
    CreateLeadsComponent,
    CreateUserComponent,
    ReloadComponent,
    CreateCampaignsComponent,
    CreateSourceComponent,
    MapDetailsComponent,
    CreateProjectComponent,
    CreateMailModelCampaignComponent,
    CreateAreChooseTageComponent,
    CommanForAllComponent,
    ShowTemplateViewComponent,
    LeadAutomationComponent,
    NodeComponent,
    OutputSocketComponent,
    InputSocketComponent,
    AutomationActionsListComponent,
    CreateActionComponent,
    LeadScoreComponent,
    LeadScoreConfigurationComponent,
    ChooseActionsConditionsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    HttpClientModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatFormFieldModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    DragDropModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ['localhost:4200','localhost:5000', 'https://swagger.leadgourmet.com'],
      },
    }),
    AgmCoreModule.forRoot({
      apiKey:'AIzaSyC6aP1kQgXDXbroJouE110syf0W9wVjIUc'
    }),
    AgmJsMarkerClustererModule
  ],
  providers: [

    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyInterceptorInterceptor,
      multi: true,
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
