import { Component } from "@angular/core";

@Component({
  selector: "output-socket",
  templateUrl: "./output-socket.component.html",
  styleUrls: ["./output-socket.component.scss"]
})
export class OutputSocketComponent {
  constructor() {}
}
