<div class="d-flex flex-row align-items-center modal-header">
    <mat-icon mat-dialog-close>keyboard_backspace</mat-icon>
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <span *ngIf="screnType=='CreatePlan'"> 
            <img class="titleimagestyle" src="../../../../../../assets/svg-icons/plan.svg" alt="">
            Create Plan
        </span>
        <span *ngIf="screnType=='CreateContiction'">
            <img class="titleimagestyle" src="../../../../../../assets/svg-icons/condition.svg" alt="">
            Create Contiction
        </span>
        <span *ngIf="screnType=='CreateAction'">
            <img class="titleimagestyle" src="../../../../../../assets/svg-icons/Action.svg" alt="">
             Create Action
            </span>
    </h2>
</div>
<mat-dialog-content class="mr-0 ml-0">
<!-- 0000000000000  -->
<ng-container *ngIf="screnType=='CreatePlan'">
    <form [formGroup]="Form">
        <div class="row mt-2">
            <mat-form-field >
                <mat-label>Plan Name</mat-label>
                <input matInput  formControlName="Name">
            </mat-form-field>
        </div>
        <div class="row "> 
            <mat-form-field >
                <mat-label>Description</mat-label>
                <input matInput  formControlName="Description">
            </mat-form-field>
        </div>

    <div class="row ">
        <mat-form-field class="example-chip-list" style="width: 100% !important;">
            <mat-label>{{'keyword'}}</mat-label><br>
            <mat-chip-list #chipGridforkeyword aria-label="Fruit selection" class="keywordlist">
                <mat-chip-row class="keywordrow" *ngFor="let keyword of keywordlist">
                    <button class="keywordbutton">
                        <span class="keywordpostion">{{keyword|titlecase}}</span>
                        <mat-icon class="keywordcancelicon" (click)="remove(keyword)">cancel</mat-icon>
                    </button>
                </mat-chip-row>
            </mat-chip-list>
            <input [matChipInputFor]="chipGridforkeyword" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)" />
        </mat-form-field>
    </div>
        <div class="row">
            <mat-slide-toggle formControlName="Active">Active</mat-slide-toggle>
        </div>
    </form>
    <div class="row mt-2 justify-content-center">
        <div class="col-4">
            <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"}}</button>
        </div>
        <div class="col-4">
            <button mat-raised-button class="w-100 b-blue" 
             [disabled]="!Form.valid" (click)="Submit()">{{"Apply"}}</button>
        </div>
    </div>
</ng-container>


<!-- CreateContiction  -->
<ng-container *ngIf="screnType=='CreateContiction'">
    <form [formGroup]="Form1">
        <div class="row mt-2">
            <mat-form-field >
                <mat-label> Name</mat-label>
                <input matInput  formControlName="Name">
            </mat-form-field>
        </div>
        <div class="row "> 
            <mat-form-field >
                <mat-label>Description</mat-label>
                <input matInput  formControlName="Description">
            </mat-form-field>
        </div>

        <div class="row">
            <mat-slide-toggle formControlName="Active">Active</mat-slide-toggle>
        </div>
    </form>
    <div class="row mt-2 justify-content-center">
        <div class="col-4">
            <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"}}</button>
        </div>
        <div class="col-4">
            <button mat-raised-button class="w-100 b-blue" 
             [disabled]="!Form1.valid" (click)="submit1()">{{"Apply"}}</button>
        </div>
    </div>
</ng-container>

<!-- CreateAction  -->
<ng-container *ngIf="screnType=='CreateAction'">
    <form [formGroup]="Form2">
        <div class="row mt-2">
            <mat-form-field >
                <mat-label> Name</mat-label>
                <input matInput  formControlName="Name">
            </mat-form-field>
        </div>
        <div class="row "> 
            <mat-form-field >
                <mat-label>Description</mat-label>
                <input matInput  formControlName="Description">
            </mat-form-field>
        </div>
        <div class="row">
            <mat-slide-toggle formControlName="Active">Active</mat-slide-toggle>
        </div>
    </form>
    <div class="row mt-2 justify-content-center">
        <div class="col-4">
            <button mat-raised-button mat-dialog-close class="W-blue">{{"Cancel"}}</button>
        </div>
        <div class="col-4">
            <button mat-raised-button class="w-100 b-blue"
             [disabled]="!Form2.valid" (click)="submit2()">{{"Apply"}}</button>
        </div>
    </div>
</ng-container>
</mat-dialog-content>
