<div class="row headderBody">
    <div class="col-9 p-0">
        <h3 style="text-align: center;font-weight: 700;"> {{headerTitle|titlecase}}</h3>
    </div>
    <div class="col-1 p-0">
        <span style="cursor: pointer;">
            <mat-icon style="position: relative;top: 8px;color: red;">help_outline</mat-icon>
        </span>
    </div>
    <div class="col-1 p-0">
        <span style="cursor: pointer;">
            <mat-icon style="position: relative;top: 8px;color: red;">notification_important</mat-icon>
        </span>
    </div>
    <div class="col-1 p-0">
        <span (click)="logout()" style="cursor: pointer;">
            <mat-icon style="position: relative;top: 8px;color: red;">power_settings_new</mat-icon>
        </span>
    </div>
</div>