import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmailComponent } from 'src/app/pages/emails/emails.component';
import { ApiServiceService } from 'src/app/service/api-service.service';
@Component({
  selector: 'app-comman-for-all',
  templateUrl: './comman-for-all.component.html',
  styleUrls: ['./comman-for-all.component.scss']
})
export class CommanForAllComponent implements OnInit {
  Form: FormGroup;
screenTyle=''
FromMediaLibrarylist:any
htmlContent=''

leedAitomationList=[
  {name:'siva'},
  {name:'kumar'},
  {name:'babu'},
]
  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private loginservice: ApiServiceService,
    private snackBar: MatSnackBar,
    private matDialogRef: MatDialogRef<CommanForAllComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public datepipe: DatePipe,
  ) {

    this.Form = this.formBuilder.group({
      TsetEmails: [''],
    });
   }

  ngOnInit(): void {
    if(this.data){
      this.screenTyle=this.data.ScreenType
    }
    if(this.data.ScreenType=='FromMediaLibrary'){
     this.loginservice.GetAllTempsForDD(this.data.CampaignId).subscribe((result)=>{
      if(result){
        this.FromMediaLibrarylist=result
        console.log(result)
      }
     })
    }
    console.log(this.data)
  }

  

  ImportData(value){
    this.matDialogRef.close(value)
  }

  RootImmediatebutton(value){
    this.matDialogRef.close(value)
  }

  Testemailbutton(){
  this.matDialogRef.close(this.Form.value.TsetEmails)
  }

  actionvalue(value){
    this.matDialogRef.close(value)

  }

  FromMediaLibrary(data){
    console.log(data)
    this.matDialogRef.close(data)
    }



AWSURLToHTMLconvert(awsUrl){
  this.loginservice.getHtmlFile(awsUrl).subscribe(
    (data) => {
      this.htmlContent = data;
      console.log(this.htmlContent)
    },
    (error) => {
      console.error('Error fetching HTML:', error);
    }
  );
}

}
