import { Component, OnInit,Inject } from '@angular/core';
import { inject } from '@angular/core/testing';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiServiceService } from 'src/app/service/api-service.service';
import { CreateProjectComponent } from '../create-project/create-project.component';
@Component({
  selector: 'app-create-utm',
  templateUrl: './create-utm.component.html',
  styleUrls: ['./create-utm.component.css']
})
export class CreateUtmComponent implements OnInit {
  Form: FormGroup;
  AllProjects: any;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private loginservice: ApiServiceService,
    private matDialogRef: MatDialogRef<CreateUtmComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,


  ) {
    this.Form = this.formBuilder.group({
      id: [''],
      projectName: ['',[Validators.required]],
      baseUrl: ['',[Validators.required]],
      source: [''],
      medium: [''],
      campaign: [''],
      content: [''],
      shortUrl:[false],
      term: [''],
      alias: [''],
      keywords: ['']
    });
  } 
  ngOnInit(): void {
    this.getAllProjects()
    if(this.data.ScreenType=='Create'){

    }else if(this.data.ScreenType=='Update'||this.data.ScreenType=='dublicate'){
this.GetUTMBuilderById()
    }
console.log(this.data)
  }

  getAllProjects() {
    this.loginservice.AllProjects().subscribe((result: any) => {
      if (result) {
        this.AllProjects = result;
      }
    });
  }

  GetUTMBuilderById(){
    this.loginservice.GetUTMBuilderById(this.data.data.id).subscribe((result:any)=>{
      if(result){
        console.log(result)
        this.Form.get('id')?.setValue(result?.id)
        this.Form.get('projectName')?.setValue(result?.projectName)
        this.Form.get('baseUrl')?.setValue(result?.baseUrl)
        this.Form.get('source')?.setValue(result?.source)
        this.Form.get('campaign')?.setValue(result?.campaignName)
        this.Form.get('content')?.setValue(result?.content)
        this.Form.get('shortUrl')?.setValue(result?.shortUrl)
        this.Form.get('medium')?.setValue(result?.medium)
        this.Form.get('term')?.setValue(result?.term)
        this.Form.get('alias')?.setValue(result?.alias)
        this.Form.get('keywords')?.setValue(result?.keywords)
      }
    })
  }

  closedialogbox(){

  }

  onSubmit() {
    if(this.data.ScreenType=='Create'||this.data.ScreenType=='dublicate'){
      this.Form.removeControl('id');
      const data = this.Form.value
      this.loginservice.CreateUTMBuilder(data).subscribe((result: any) => {
        if (result) {
          this.snackBar.open('UTM Builder createrd', '', {duration: 3000 }); 
          this.matDialogRef.close(true)
        }
      },(error)=>{
        console.log(error)
        if(error.status==200){
          this.snackBar.open('UTM Builder createrd', '', {duration: 3000 });
          this.matDialogRef.close(true)
        }
      })
    }else if(this.data.ScreenType=='Update'){
      const data = this.Form.value
      this.loginservice.CreateUTMBuilder(data).subscribe((result: any) => {
        if (result) {
          this.snackBar.open('UTM Builder Update', '', {duration: 3000 });
          this.matDialogRef.close(true)
        }
      },(error)=>{
        if(error.status==200){
          this.snackBar.open('UTM Builder Update', '', {duration: 3000 });
          this.matDialogRef.close(true)
        }
      }) 
       }

 
  }

  CreateProject(){
    const CreateProject = this.dialog.open(CreateProjectComponent, {
      disableClose: true,
      width: '500px',
      data:{screenType:'CreateProject'}
    });
    CreateProject.afterClosed().subscribe(result => {
      if(result){
        console.log(result)
        this.loginservice.CrateProject(result).subscribe((result: any) => {
          if (result) {
            this.getAllProjects()
          }
        });

      }
    });
  }





}