<!-- 11111111  -->
<!-- work flow  -->
<!-- [style.transform]="'scale(' + zoomFactor + ')'" -->
<section>
	<div class="row m-0">
		<div class="svg-area">
			<svg class="full-screen">
				<path *ngFor="let path of paths" class="svg-path" stroke-dasharray="7, 5" [attr.d]="getD(path)" />
			</svg>
			<node *ngFor="let node of nodes" [node]="node" (updatenodepotiont)="Updatenodepotiont($event)"
			(deletenodepotiont)="Deletenodepotiont($event)" (changePosition)="handleChangeNodePosition($event)"
			(addNewItem)="addNewPopupItem($event)"></node>
		</div>
	</div>
</section>
 

<!-- 22222222  -->
<!-- apply button  -->
<span mat-fab style="background-color: transparent !important;" class="fab-table-group bottom_fab_2">
	<button *ngIf="nodes.length!=0" id="add-button1" mat-fab (click)="getFinalflowchart()">
		Apply
	</button><br>
	<button *ngIf="nodes.length==0" id="add-button2" mat-fab (click)="startworkflow()">
		<mat-icon style="color: white;">add</mat-icon>
	</button><br>
	<button *ngIf="nodes.length>3" id="add-button2" mat-fab>
		<button class="zoomebutton" (click)="zoomIn()">+</button>
		<button class="zoomebutton"  (click)="zoomOut()">-</button>
	</button>
</span>