import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LeadScoreConfigurationComponent } from 'src/app/components/lead-score-configuration/lead-score-configuration.component';
import { ApiServiceService } from 'src/app/service/api-service.service';

@Component({
  selector: 'app-lead-score',
  templateUrl: './lead-score.component.html',
  styleUrls: ['./lead-score.component.scss'],
})
export class LeadScoreComponent implements OnInit {
  Form: FormGroup;
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  progressValue = 60;
  customColor = 'purple';
  progress: number = 60;
  radius: number = 26;
  tapnumber = 0;
  leads: any = [
    {  email: 'john@example.com', score: 30 },
    {  email: 'jane@example.com', score: -60 },
    {  email: 'bob@example.com', score: 75 },
    {  email: 'john@example.com', score: 80 },
    {  email: 'jane@example.com', score: 60 },
    {  email: 'bob@example.com', score: 75 },
    {  email: 'john@example.com', score: -80 },
    {  email: 'jane@example.com', score: 30 },
    {  email: 'bob@example.com', score: -75 },
  ];
  AllProjects: any = [];
  CampaignType = '';
  configuration = '';
  Allconfiguration: any = [];
  AllconfigurationDD: any = [];
  AllcampaignsDD: any = [];
  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private loginservice: ApiServiceService
  ) {
    this.Form = this.formBuilder.group({
      ProjectID: ['', [Validators.required]],
      CampaignID: ['', [Validators.required]],
      configurationID: ['', [Validators.required]],
    });
  }

  private circumference: number = 2 * Math.PI * this.radius;
  calculateDashoffset(progress): number {
    const percent = progress > 100 ? 100 : progress < 0 ? 0 : progress;
    return this.circumference - (percent / 100) * this.circumference;
  }

  ngOnInit(): void {
    this.getAllConfigurations();
    this.getAllConfigurationsforDD();
    this.getAllProjects();
  }

  getAllProjects() {
    this.loginservice.AllProjects().subscribe((result: any) => {
      if (result) {
        this.AllProjects = result;
      }
    });
  }

  tabChange(tabIndex: number): void {
    this.tapnumber = tabIndex;
  }
  tabChangecolor(value) {
    if (this.tapnumber === value) {
      return 'titleofchart1';
    } else {
      return 'titleofchart';
    }
  }

  Viewapply() {
    console.log(this.Form.value);
      const formData = new FormData();
      formData.append( 'LeadScoreConfigId', this.Form.get('configurationID').value);
      if (this.Form.value.CampaignID.length != 0) {
        this.Form.value.CampaignID.forEach((element, index) => {
          formData.append('CampaignId[' + index + ']', element);
        });
      }
      this.loginservice.CalculateLeadscore(formData).subscribe((result) => {
        if (result) {
          this.leads=result
          console.log(result);
        }
      });
  }

  getAllConfigurationsforDD() {
    this.loginservice.GetLeadScoringCongfigDD().subscribe((result) => {
      if (result) {
        this.AllconfigurationDD = result;
      }
    });
  }
  getAllConfigurations() {
    this.loginservice.GetLeadScoringCongfig().subscribe((result) => {
      if (result) {
        this.Allconfiguration = result;
        console.log(result);
      }
    });
  }
  GetAllCampaigns(Projectid) {
    this.GetAllCampaignsDD(Projectid);
  }
  GetAllCampaignsDD(Projectid) {
    this.loginservice.GetAllCampaignsDD(Projectid).subscribe((result: any) => {
      if (result) {
        this.AllcampaignsDD = result;
      }
    });
  }

  showConfigurationpopup() {
    let dialogRef = this.dialog.open(LeadScoreConfigurationComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getAllConfigurations();
      }
    });
  }
}
