import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Node, Position } from "../../models/model";
import { CdkDragMove } from "@angular/cdk/drag-drop";
import { IOUtils } from "../../utils/io.utils";

@Component({
  selector: "node",
  templateUrl: "node.component.html",
  styleUrls: ["node.component.scss"]
})
export class NodeComponent {
  @Input() node: Node;
  @Output() addNewItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() deletenodepotiont: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatenodepotiont: EventEmitter<any> = new EventEmitter<any>();

  @Output() changePosition: EventEmitter<{
    id: number; 
    position: Position;
  }> = new EventEmitter<{ id: number; position: Position }>();

  handleDragMoved(event: CdkDragMove) {
    const newPosition = IOUtils.getElementPosition(
      event.source.element.nativeElement
    );
    this.changePosition.emit({ id: this.node.id, position: newPosition });
  }



  openPopup(node: any,type) {
    this.addNewItem.emit({ node: node,type:type });
  }

  Updatenodepotiont(node: any){
    this.updatenodepotiont.emit({ node: node });

  }
  Deletenodepotiont(node: any){
    this.deletenodepotiont.emit({ node: node });

  }
}
