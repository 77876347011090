<div class="d-flex flex-row align-items-center modal-header">
    <mat-icon mat-dialog-close>keyboard_backspace</mat-icon>
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <span>  UTM Builder</span>
    </h2>
</div>
<mat-dialog-content class="mr-0 ml-0"> 
    <form [formGroup]="Form">
        <div class="row "> 
            <div class="col-10">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label><mat-icon>folder</mat-icon> Select Project *</mat-label>
                <mat-select formControlName="projectName">
                    <mat-option *ngFor="let x of AllProjects"
                        [value]="x.id">
                        <b>{{x.projectName}}</b>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            </div>
            <div class="col-2"> 
                <mat-icon style="margin-top: 10px;" (click)="CreateProject()">add_box</mat-icon>
            </div>
         
        </div>
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>Website URL </mat-label>
                <input matInput  formControlName="baseUrl">
                <mat-hint>The full website URL (e.g. https://www.example.com)</mat-hint>

            </mat-form-field>
        </div>
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>Campaign Source</mat-label>
                <input matInput  formControlName="source">
                <mat-hint>The referrer (e.g. google, newsletter)</mat-hint>

            </mat-form-field>
        </div>
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>Campaign Medium</mat-label>
                <input matInput  formControlName="medium">
                <mat-hint>Marketing medium (e.g. cpc, banner, email)</mat-hint>

            </mat-form-field>
        </div>
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>Campaign Name</mat-label>
                <input matInput  formControlName="campaign">
                <mat-hint>Product, promo code</mat-hint>

            </mat-form-field>
        </div>
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>Campaign Term</mat-label>
                <input matInput  formControlName="term">
                <mat-hint>Identify the paid keywords</mat-hint>

            </mat-form-field>
        </div>
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>Campaign Content</mat-label>
                <input matInput  formControlName="content">
                <mat-hint>Use to differentiate ads</mat-hint>

            </mat-form-field>
        </div>

        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>Alias</mat-label>
                <input matInput  formControlName="alias">
            </mat-form-field>
        </div>
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>keywords</mat-label>
                <input matInput  formControlName="keywords">
            </mat-form-field>
        </div>
        <div class="row ">
            <section class="example-section">
                <mat-checkbox formControlName="shortUrl" class="example-margin">Do wish to short the URL .......?</mat-checkbox>
              </section>
            
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="my-1">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button class="b-blue" [disabled]="!Form.valid" (click)="onSubmit()">Apply</button>
</mat-dialog-actions>

