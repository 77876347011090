<div class="d-flex flex-row align-items-center modal-header">
    <mat-icon mat-dialog-close>keyboard_backspace</mat-icon>
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <span> Create Lead</span>
    </h2>
</div>
<mat-dialog-content class="mr-0 ml-0">
    <form [formGroup]="Form">
     
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="my-1">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button class="b-black" (click)="onSubmit()">Apply</button>
</mat-dialog-actions>