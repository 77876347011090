import { Component, OnInit ,Inject} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiServiceService } from 'src/app/service/api-service.service';
import { CreateEmailComponent } from '../create-email/create-email.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {
  userForm: FormGroup;
  profilePick: any;
  profilePickid: any
  constructor(
    private formBuilder: FormBuilder,
    public apiservice: ApiServiceService,
    private matDialogRef: MatDialogRef<CreateUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.userForm = this.formBuilder.group({
      id: [''],
      created: [''],
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      Gender: ['', Validators.required],
      Position: ['', Validators.required],
      Email: ['', Validators.required],
      Address: ['', Validators.required],
      ZipCode: [''],
      PhoneNumber: [''],
      City: ['', Validators.required],
      Country: ['', Validators.required],
      Latitude:[0],
      Longitude:[0],
      Profile: [''],
    });
  }
  ngOnInit(): void {
console.log(this.data)
  }
  onFileChange(e: any) {
    var file = e.target.files[0];
    var formData = new FormData();
    formData.append('file', file);
    this.userForm.get('Profile')?.setValue(file, { emitModelToViewChange: false })
    if (e.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event: any) => {
        this.profilePick = event.target.result
      }
    }

  }
  get f() {
    return this.userForm.controls;
  }

  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.userForm.get('Profile')?.setValue(file);
    }
  }

  onSubmit() {
    console.log(this.userForm.value)
    const formData = new FormData();
    formData.append('FirstName', this.userForm.get('FirstName').value);
    formData.append('LastName', this.userForm.get('LastName').value);
    formData.append('Gender', this.userForm.get('Gender').value);
    formData.append('Position', this.userForm.get('Position').value);
    formData.append('Email', this.userForm.get('Email').value);
    formData.append('Address', this.userForm.get('Address').value);
    formData.append('ZipCode', this.userForm.get('ZipCode').value);
    formData.append('City', this.userForm.get('City').value);
    formData.append('Country', this.userForm.get('Country').value);
    formData.append('Latitude', this.userForm.get('Latitude').value);
    formData.append('Longitude', this.userForm.get('Longitude').value);
    formData.append('Profile', this.userForm.get('Profile').value);
    formData.append('PhoneNumber', this.userForm.get('PhoneNumber').value);
    this.apiservice.Createuser(formData).subscribe((result) => {
      if (result) {
        this.matDialogRef.close(true)
      }
    })
  }
}
