<div class="d-flex flex-row align-items-center modal-header">
    <mat-icon mat-dialog-close>keyboard_backspace</mat-icon>
    <h2 mat-dialog-title class="m-0 dialog-title text-center w-100">
        <span *ngIf="screentype==='updateaction'"> Update Action</span>
        <span *ngIf="screentype==='createaction'"> Create Action</span>
    </h2>
</div>
<mat-dialog-content class="mr-0 ml-0">
    <form [formGroup]="Form">
        <div class="row "> 
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Scenario</mat-label> 
                <mat-select formControlName="ScenarioId">
                    <mat-option value="1">optuin1</mat-option>
                    <mat-option value="2">option2</mat-option>
                </mat-select>
            </mat-form-field>
    </div>
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>Title</mat-label>
                <input matInput  formControlName="Title">
            </mat-form-field>
        </div>
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <input matInput  formControlName="Description">
            </mat-form-field>
        </div>
        <div class="row "> 
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>ActionType</mat-label>
                <mat-select formControlName="ActionType">
                    <mat-option [value]="0">Email</mat-option>
                    <mat-option [value]="1">Notification</mat-option>
                    <mat-option [value]="2">SMS</mat-option>
                    <mat-option [value]="3">Call</mat-option>
                    <mat-option [value]="4">LinkedIn</mat-option>
                </mat-select>
            </mat-form-field>
    </div>
    <div class="row">
        <div class="col-12">
              <input type="file" id="file" (change)="onFileChange($event)" formControlName="MediaFile">
          </div>
    </div>
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>MediaContent</mat-label>
                <input matInput  formControlName="MediaContent">
            </mat-form-field>
        </div>
        <div class="row ">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>DelayPeriod</mat-label>
                <mat-select formControlName="DelayPeriod">
                    <mat-option [value]="0">Minute</mat-option>
                    <mat-option [value]="1">Hour</mat-option>
                    <mat-option [value]="2">Day</mat-option>
                    <mat-option [value]="3">Month</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="row ">
            <mat-form-field appearance="outline">
                <mat-label>Objective</mat-label>
                <input matInput  formControlName="Objective">
            </mat-form-field>
        </div>
        <div class="row ">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Action Status</mat-label>
                <mat-select formControlName="Status">
                    <mat-option [value]="0">Pending</mat-option>
                    <mat-option [value]="1">InProgress</mat-option>
                    <mat-option [value]="2">Success</mat-option>
                    <mat-option [value]="3">Fail</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="row ">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label><mat-icon>calendar_today</mat-icon> StartDateTime</mat-label>
                <input matInput [matDatepicker]="startdatepicker1" style="color: black;"
                    formControlName="StartDateTime">
                <mat-datepicker-toggle matSuffix [for]="startdatepicker1"></mat-datepicker-toggle>
                <mat-datepicker #startdatepicker1></mat-datepicker>
            </mat-form-field>

        </div>
        <div class="row mb-2" style="background-color: rgb(202, 198, 198);color: black;padding: 10px 4px 2px;">
            <div class="col-10">
                Conditions
            </div>
            <div class="col-2">
                <mat-icon style="color: black !important;"
                    (click)="addConditionViewModels()">add_box</mat-icon>
            </div>
        </div>

        <div formArrayName="ConditionViewModels">
            <div class="row" *ngFor="let file of getConditionViewModels().controls; let i=index" [formGroupName]="i">
                <div class="row">
                    <div class="col-4">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>User Action Type</mat-label>
                            <mat-select formControlName="userActionType">
                                <mat-option [value]="0">Pending</mat-option>
                                <mat-option [value]="1">Sent</mat-option>
                                <mat-option [value]="2">Failed</mat-option>
                                <mat-option [value]="3">Click</mat-option>
                                <mat-option [value]="3">Open</mat-option>
                                <mat-option [value]="3">Lead</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Comparison <span class="danger"></span></mat-label>
                            <input matInput type="text"  formControlName="comparison">
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Value<span class="danger"></span></mat-label>
                            <input matInput  type="text" formControlName="value">
                        </mat-form-field>
                    </div>
                    <div class="col-2">
                        <mat-icon style="margin-top: 10px;color:black;"
                            (click)="Removecondictuon(i)">delete</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end" class="my-1">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button *ngIf="screentype==='createaction'" class="b-blue"  (click)="onSubmit()">Apply</button>
    <button mat-raised-button *ngIf="screentype==='updateaction'" class="b-blue"  (click)="onSubmit()">Update</button>
</mat-dialog-actions>
