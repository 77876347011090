import { Component, OnInit,Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CreateEmailComponent } from 'src/app/components/create-email/create-email.component';
import { DeleteDialogComponent } from 'src/app/components/delete-dialog/delete-dialog.component';
import { ApiServiceService } from 'src/app/service/api-service.service';

@Component({
  selector: 'app-email',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.css']
})
export class EmailComponent implements OnInit {
  @Input() dataMessageResults: any[];
  tabnumber: number = 0
  mailDetails: any
  datalenght:number=10
sourceDetails:any
  constructor(
    private loginservice: ApiServiceService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {

  }
  ngOnInit(): void {
    console.log(this.data.data)
    this.sourceDetails=this.data.data
    this.getAllmailes(10,1)
  }


  getHeaders() {
    let headers: string[] = [];
    if(this.mailDetails) {
      this.mailDetails.forEach((value) => {
        Object.keys(value).forEach((key) => {
          if(!headers.find((header) => header == key)){
            headers.push(key)
          }
        })
      })
    }
    return headers;
  }


  next(e:any) {
    this.getAllmailes(20, e.pageIndex + 1, );
}
  getAllmailes(size:number,pagenumber:number) {
    this.loginservice.getAllmailesList(size,pagenumber,this.sourceDetails.id?this.sourceDetails.id:this.sourceDetails.sourceId).subscribe((result: any) => {
      if (result) {
        this.mailDetails = result
      }
    })

  }

  tabChange(tabIndex: number): void {
    console.log(tabIndex);
    this.tabnumber = tabIndex;

  }

  deleteemail(data:any){
    let dialogRef = this.dialog.open(DeleteDialogComponent, {
      height: '200px',
      width: '300px',
      data: {data:data,ScreenType:'deleteemail'}
      
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.getAllmailes(10,1)
      }
    });
  }


  createEmail() {
    const createUserDialog = this.dialog.open(CreateEmailComponent, {
      disableClose: true,
      width: '500px',
      data: {data:this.sourceDetails}
    });
    createUserDialog.afterClosed().subscribe(result => {
      if(result){
        this.getAllmailes(10,1)
      }
    });
  }

}
