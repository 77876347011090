import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-choose-actions-conditions',
  templateUrl: './choose-actions-conditions.component.html',
  styleUrls: ['./choose-actions-conditions.component.scss']
})
export class ChooseActionsConditionsComponent implements OnInit {

  constructor(
    private matDialogRef: MatDialogRef<ChooseActionsConditionsComponent>

  ) {

   }

  ngOnInit(): void {
  }




  radioChange(value){
this.matDialogRef.close(value)
  }

}
