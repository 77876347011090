<div class="row align-items-center modal-header">
    <div class="col-2"><mat-icon mat-dialog-close>keyboard_backspace</mat-icon></div>
    <div class="col-8"><h2 mat-dialog-title
            class="m-0 dialog-title text-center w-100">
            <span>Contact List</span>
        </h2></div>
    <div class="col-2">
    </div>
</div>
<mat-dialog-content class="mr-0 ml-0">
<div class="row m-0">
    <div class="row mt-4">
        <div class="col-8">
            <mat-form-field class="w-100">
                <mat-label>Search</mat-label>
                <input matInput placeholder="Placeholder">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div>
        <div class="col-4">
            <button mat-raised-button class="right b-blue w-100"
                (click)="createEmail()">
                Create
            </button>
        </div>
    </div>
    <table border>
        <thead>
            <tr>
                <th *ngFor="let header of getHeaders()">{{header}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of mailDetails">
                <td *ngFor="let key of getHeaders()">{{item[key]}}</td>
            </tr>
        </tbody>
    </table>

    <mat-paginator [length]="datalenght" [pageSize]="10" (page)="next($event)">
    </mat-paginator>
</div>
</mat-dialog-content>