import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiServiceService } from 'src/app/service/api-service.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: any;
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  spinnerloding = false;
  fieldTextType = 'password'
  Height!: Window["innerHeight"];
  imagecount = 1
  image = '../../../../assets/images/loginimage.jfif'
  showLogin:boolean=false
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private loginservice: ApiServiceService
  ) {
    // this.loginForm = this.formBuilder.group({
    //   username: ['krishnatestst@gmail.com', [Validators.required, Validators.email]],
    //   password: ['abcDEF123$', [Validators.required]]
    // });
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }


  ngOnInit(): void {

  }
  showpassword(value: string) {
    console.log(value)
    if (value == 'on') {
      this.fieldTextType = 'password'
    } else if (value == 'off') {
      this.fieldTextType = 'text'

    }
  }

  onSubmit() {
    this.loginservice.login(this.loginForm.value).subscribe(
      (result: any) => {
        if (result) {
          localStorage.setItem("access_token", result['accessToken']);
          localStorage.setItem("UserId", result.user.id);
          localStorage.setItem("position", result.user.position);
          localStorage.setItem('headdertitle', 'UTM Builder');
          this.router.navigate(['/home/dash/utm'])
        }
      })
  }

}
