<div class="loginbody">
    <div>
        <mat-card class="login-card mat-elevation-z8 p-4 " >
            <form [formGroup]="loginForm" novalidate>
                <mat-card-content>
                    <div class="d-flex justify-content-center mb-3" >
                        <img style="width: 300px; height: 100px; object-fit: contain;" src="../../../assets/images/utm-logo.png"/>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-icon style="color: black;" matPrefix>person</mat-icon>
                                <mat-label>Username</mat-label>
                                <input matInput type="text" placeholder="Username" formControlName="username">
                                <mat-error *ngIf="loginForm.controls['username'].hasError('required')">
                                    Username is <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-icon style="color: black;"  matPrefix>lock</mat-icon>
                                <mat-label>Password</mat-label>
                                <input matInput [type]="fieldTextType=='text'? 'text':'password'" placeholder="Password"
                                    formControlName="password">
                                <mat-icon style="color: black;" *ngIf="fieldTextType=='password'" (click)="showpassword('off')"
                                    matSuffix>visibility_off</mat-icon>
                                <mat-icon style="color: black;" *ngIf="fieldTextType=='text'" (click)="showpassword('on')"
                                    matSuffix>visibility</mat-icon>
                                <mat-error *ngIf="loginForm.controls['password'].hasError('required')">
                                    Password is <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <span><mat-checkbox class="ml-1">Remember Me</mat-checkbox></span>
                         <span><a mat-button>Forgot Password?</a></span>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-4">
                            <button mat-raised-button style="background-color: #3880ff;color: white;"
                                class="w-100 b-black" (click)="onSubmit()">Login</button>
                        </div>
                    </div>
                </mat-card-content>

            </form>
        </mat-card>
    </div>
</div>
<ng-container>
    
</ng-container>